import { ButtonType } from "@thekeytechnology/epic-ui";
import { useFormik } from "formik";
import { useFragment, useMutation } from "react-relay";
import { BranchDropdownComponent } from "@components/branch-dropdown/branch-dropdown.component";
import { Button, ButtonVariant } from "@components/button";
import { DefaultSwitchComponent, DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { TeamSizeDropdownComponent } from "@components/team-size-dropdown";
import { ValidatedField } from "@components/ValidatedField";
import { WarningUnsavedChangesDialog } from "@components/WarningUnsavedChangesDialog";
import { FileSelectionField, type FileV2 } from "@features/files/file-selection-field";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { useCallbackPrompt } from "@hooks/UseCallBackPrompt";
import {
	type Branch,
	type TeamSize,
	type editUserForm_EditMutation,
} from "@relay/editUserForm_EditMutation.graphql";
import { type editUserForm_UserFragment$key } from "@relay/editUserForm_UserFragment.graphql";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
	selectArrayOfEditedForms,
} from "@store/slices/CoreSlice";
import { useTypedDispatch, useTypedSelector } from "@store/store.redux";
import { USER_FRAGMENT, EDIT_USER_MUTATION } from "./edit-user-form.graphql";
import { fourColumnFieldWrapperClass, twoColumnFieldWrapperClass } from "./edit-user-form.styles";
import {
	EditUserFormValidationSchema,
	type EditUserFormProps,
	type EditUserFormState,
} from "./edit-user-form.types";

export const EditUserForm = ({ userFragmentRef }: EditUserFormProps) => {
	const dispatch = useTypedDispatch();
	const arrayOfEditedForms = useTypedSelector(selectArrayOfEditedForms);
	const isEditing = arrayOfEditedForms.length > 0;
	const [showPrompt, confirmNavigation, cancelNavigation, setShowPrompt] =
		useCallbackPrompt(isEditing);

	const user = useFragment<editUserForm_UserFragment$key>(USER_FRAGMENT, userFragmentRef ?? null);

	const [updateUser, isUpdatingUser] = useMutation<editUserForm_EditMutation>(EDIT_USER_MUTATION);

	const formId = "EditUserForm";
	const formik = useFormik<EditUserFormState>({
		initialValues: {
			firstName: user?.extension?.firstName ?? "",
			lastName: user?.extension?.lastName ?? "",
			email: user?.email ?? "",
			activated: user?.activated ?? false,
			branch: user?.extension?.branch ?? undefined,
			teamSize: user?.extension?.teamSize ?? undefined,
			position: user?.extension?.position ?? undefined,
			adsOptIn: user?.extension?.adsOptIn ?? false,
			image: user?.extension?.avatar as FileV2,
		},
		validationSchema: EditUserFormValidationSchema,
		onSubmit: (values, { setSubmitting }) => {
			if (!user) return;

			updateUser({
				variables: {
					input: {
						userId: user.id,
						firstName: values.firstName,
						lastName: values.lastName,
						email: values.email,
						activated: values.activated,
						adsOptIn: values.adsOptIn,
						branch: values.branch,
						teamSize: values.teamSize,
						position: values.position,
						imageId: values.image?.id,
					},
				},
			});

			setSubmitting(false);
			dispatch(resetArrayOfEditedForms());
		},
	});

	const handleFieldOnChange = () => {
		dispatch(addEditedFormToEditedFormsArray({ form: formId }));
	};

	const canUpload = useHasPermissions(["UserInAccountPermission_AuthAdmin_Modify"]);
	const canDelete = useHasPermissions("onlyOwnerOfRoot");

	return (
		<>
			{showPrompt && (
				<WarningUnsavedChangesDialog
					confirmNavigation={confirmNavigation}
					setShowDialog={setShowPrompt}
					cancelNavigation={cancelNavigation}
				/>
			)}
			<form onSubmit={formik.handleSubmit}>
				<ValidatedField<EditUserFormState, FileV2>
					name="image"
					label="Profilbild"
					onChange={handleFieldOnChange}
					formikConfig={formik}
					component={({ fieldName, fieldValue, updateField, onChange }) => {
						return (
							<FileSelectionField
								name={fieldName}
								selectedFile={fieldValue}
								setSelectedFile={updateField}
								filterByFileTypes={["image/png", "image/jpg", "image/jpeg"]}
								onChange={onChange}
								canUploadFiles={canUpload}
								canDeleteFiles={canDelete}
							/>
						);
					}}
				/>
				<div className={twoColumnFieldWrapperClass}>
					<ValidatedField<EditUserFormState, string>
						name="firstName"
						label="Vorname"
						required
						component={DefaultTextFieldComponent}
						onChange={handleFieldOnChange}
						formikConfig={formik}
					/>
					<ValidatedField<EditUserFormState, string>
						name="lastName"
						label="Nachname"
						required
						component={DefaultTextFieldComponent}
						onChange={handleFieldOnChange}
						formikConfig={formik}
					/>
				</div>
				<ValidatedField<EditUserFormState, string>
					name="email"
					label="E-Mail"
					required
					component={DefaultTextFieldComponent}
					onChange={handleFieldOnChange}
					formikConfig={formik}
				/>
				<ValidatedField<EditUserFormState, Branch>
					name="branch"
					label="Branche"
					component={BranchDropdownComponent}
					onChange={handleFieldOnChange}
					formikConfig={formik}
				/>
				<ValidatedField<EditUserFormState, TeamSize>
					name="teamSize"
					label="Teamgröße"
					component={TeamSizeDropdownComponent}
					onChange={handleFieldOnChange}
					formikConfig={formik}
				/>
				<ValidatedField<EditUserFormState, string>
					name="position"
					label="Position"
					component={DefaultTextFieldComponent}
					onChange={handleFieldOnChange}
					formikConfig={formik}
				/>
				<div className={fourColumnFieldWrapperClass}>
					<ValidatedField<EditUserFormState, boolean>
						name="activated"
						label="Aktiviert"
						component={DefaultSwitchComponent}
						onChange={handleFieldOnChange}
						formikConfig={formik}
					/>
					<ValidatedField<EditUserFormState, boolean>
						name="adsOptIn"
						label="Werbung Opt In"
						component={DefaultSwitchComponent}
						onChange={handleFieldOnChange}
						formikConfig={formik}
					/>
				</div>

				<Button
					type={ButtonType.Submit}
					variant={ButtonVariant.Strong}
					disabled={isUpdatingUser || !isEditing}
					label="Speichern"
					stretch
				/>
			</form>
		</>
	);
};
