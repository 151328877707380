import { EpicIcons } from "@thekeytechnology/epic-ui";
import { confirmDialog } from "primereact/confirmdialog";
import { useMutation } from "react-relay";
import { toast } from "react-toastify";
import { Button } from "@components/button";
import { resendActivationMailButton_TriggerResendActivationMutation } from "@relay/resendActivationMailButton_TriggerResendActivationMutation.graphql";
import { TRIGGER_RESEND_ACTIVATION_MUTATION } from "./resend-activation-mail-button.graphql";
import { ResendActivationMailButtonProps } from "./resend-activation-mail-button.types";

export const ResendActivationMailButton = ({ userId }: ResendActivationMailButtonProps) => {
	const [triggerResendActivation, isResendingActivation] =
		useMutation<resendActivationMailButton_TriggerResendActivationMutation>(
			TRIGGER_RESEND_ACTIVATION_MUTATION,
		);

	const handleOnResendActivation = () => {
		confirmDialog({
			header: "Aktivierungs-E-Mail erneut versenden",
			message: "Bist du sicher, dass du die Aktivierungs-E-Mail erneut versenden möchtest?",
			icon: "pi pi-info-circle",
			acceptLabel: "Ja",
			rejectLabel: "Nein",
			accept: () =>
				triggerResendActivation({
					variables: {
						input: {
							userId,
						},
					},
					onCompleted: () => {
						toast.success("Aktivierungs-E-Mail versandt");
					},
				}),
		});
	};

	return (
		<>
			<Button
				icon={EpicIcons.ENVELOPE}
				label="Aktivierungs-E-Mail erneut versenden"
				onClick={handleOnResendActivation}
				disabled={isResendingActivation}
			/>
		</>
	);
};
