import { flex } from "@styled-system/patterns";

export const emptyMessageWrapperClass = flex({
	justify: "center",
	align: "center",
});

export const actionButtonsWrapperClass = flex({
	align: "center",
	gap: "8",
});

export const loadMoreButtonWrapperClass = flex({
	justify: "center",
	align: "center",
});
