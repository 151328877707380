import graphql from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment editRootExtensionForm_QueryFragment on Query {
		...selectAcademiesTagField_QueryFragment
	}
`;
export const ROOT_EXTENSION_IMPL_FRAGMENT = graphql`
	fragment editRootExtensionForm_RootExtensionImplFragment on RootExtensionImpl {
		learnGoalsOpt
		trailerOpt
		tags {
			id
			data {
				name
			}
		}
		product {
			id
			title
		}
		diploma
	}
`;

export const SET_ROOT_EXTENSION_IMPL_MUTATION = graphql`
	mutation editRootExtensionForm_SetRootExtensionImplMutation(
		$input: SetRootExtensionImplInput!
	) {
		Admin {
			Tree {
				setRootExtensionImpl(input: $input) {
					root {
						structureDefinition {
							... on RootStructureDefinition {
								extension {
									...editRootExtensionForm_RootExtensionImplFragment
								}
							}
						}
					}
				}
			}
		}
	}
`;
