import { Button, EpicIcons } from "@thekeytechnology/epic-ui";
import { useMutation } from "react-relay";
import { createTextWithPagesElementButton_CreateTextWithPagesElementMutation } from "@relay/createTextWithPagesElementButton_CreateTextWithPagesElementMutation.graphql";
import { CREATE_TEXT_WITH_PAGES_ELEMENT_MUTATION } from "@screens/educational-offer/parts/create-text-with-pages-element-button/create-text-with-pages-element-button.graphql";
import { CreateTextWithPagesElementButtonProps } from "@screens/educational-offer/parts/create-text-with-pages-element-button/create-text-with-pages-element-button.types";

export const CreateTextWithPagesElementButton = ({
	eLearningContentNodeId,
}: CreateTextWithPagesElementButtonProps) => {
	const [createTextWithPagesElement, isCreatingTextWithPagesElement] =
		useMutation<createTextWithPagesElementButton_CreateTextWithPagesElementMutation>(
			CREATE_TEXT_WITH_PAGES_ELEMENT_MUTATION,
		);

	const handleCreateElement = () => {
		createTextWithPagesElement({
			variables: {
				input: {
					title: "Neues Element",
					task: "Neuer Aufgaben-Text",
					internalTitleForFirstPage: "Neuer Seiten-Titel",
					textForFirstPage: "Neuer Text",
					eLearningContentNodeId: eLearningContentNodeId,
				},
			},
		});
	};

	return (
		<Button
			disabled={isCreatingTextWithPagesElement}
			tooltip={`"Text in Seiten"-Baustein anlegen`}
			icon={EpicIcons.BOOK}
			onClick={handleCreateElement}
		/>
	);
};
