import { ButtonType, EpicIcons } from "@thekeytechnology/epic-ui";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { useLazyLoadQuery, useMutation, usePaginationFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { Button, ButtonVariant } from "@components/button";
import { IdDisplayField } from "@components/id-display-field";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type usersTable_AnonymizeUserMutation } from "@relay/usersTable_AnonymizeUserMutation.graphql";
import { type usersTable_Query } from "@relay/usersTable_Query.graphql";
import { type UsersTable_Refetch } from "@relay/UsersTable_Refetch.graphql";
import { type usersTable_UsersListFragment$key } from "@relay/usersTable_UsersListFragment.graphql";
import { Paths } from "@routes/paths";
import { ANONYMIZE_USER_MUTATION, QUERY, USERS_LIST_FRAGMENT } from "./users-table.graphql";
import {
	actionButtonsWrapperClass,
	emptyMessageWrapperClass,
	loadMoreButtonWrapperClass,
} from "./users-table.styles";
import { DataTable } from "../../../components/data-table";
import { UsersTableFilter } from "../users-table-filter";

export const UsersTable = () => {
	const data = useLazyLoadQuery<usersTable_Query>(
		QUERY,
		{ first: 20 },
		{ fetchPolicy: "network-only" },
	);

	const {
		hasNext,
		loadNext,
		data: {
			Admin: {
				Auth: { Users },
			},
		},
		refetch,
	} = usePaginationFragment<UsersTable_Refetch, usersTable_UsersListFragment$key>(
		USERS_LIST_FRAGMENT,
		data,
	);

	const [anonymizeUser] = useMutation<usersTable_AnonymizeUserMutation>(ANONYMIZE_USER_MUTATION);

	const navigate = useNavigate();

	const users = Users?.edges;

	const createEditUserOnClickHandler = (id: string) => () => {
		navigate(Paths.users.withId(id).edit.path);
	};

	const createDeleteUserOnClickHandler = (userId: string) => () => {
		confirmDialog({
			message: "Bist du sicher das du diesen Benutzer entfernen möchtest?",
			header: "Benutzer entfernen",
			icon: "pi pi-info-circle",
			acceptClassName: "p-button-danger",
			acceptLabel: "Ja",
			rejectLabel: "Nein",
			accept: () =>
				anonymizeUser({
					variables: {
						input: {
							userId,
						},
						connections: [Users.__id] || [],
					},
				}),
			reject: () => {},
		});
	};

	const handleLoadNextOnClick = () => {
		loadNext(20);
	};

	const handleFilterOnChange = (nameOrEmail?: string) => {
		refetch({
			nameOrEmail,
		});
	};

	const canModify = useHasPermissions(["UserInAccountPermission_AuthAdmin_Modify"]);

	return (
		<div className="mb-5">
			<DataTable
				header={<UsersTableFilter onChange={handleFilterOnChange} />}
				emptyMessage={
					<div className={emptyMessageWrapperClass}>
						<div className="mr-2">Es wurden keine Benutzer gefunden.</div>
					</div>
				}
				className="mb-3"
				value={users?.map((edge) => edge!.node!)}
			>
				{(Column) => (
					<>
						<Column
							header="ID"
							style={{ width: "5%" }}
							body={(user) => <IdDisplayField id={user.id} />}
						/>
						<Column header="Name" field="name" />
						<Column header="E-Mail" field="email" />
						<Column
							header="Aktiviert"
							body={(row) => {
								return row?.activated ? "Aktiviert" : "Nicht Aktiviert";
							}}
						/>
						{canModify && (
							<Column
								header="Aktionen"
								style={{ width: "10%" }}
								body={(user) => {
									return (
										<div className={actionButtonsWrapperClass}>
											<Button
												type={ButtonType.Button}
												onClick={createEditUserOnClickHandler(user.id)}
												tooltip="Benutzer bearbeiten"
												icon={EpicIcons.PENCIL}
											/>
											<Button
												type={ButtonType.Button}
												variant={ButtonVariant.Error}
												onClick={createDeleteUserOnClickHandler(user.id)}
												tooltip="Benutzer löschen"
												icon={EpicIcons.TRASH}
											/>
										</div>
									);
								}}
							/>
						)}
					</>
				)}
			</DataTable>
			{hasNext && (
				<div className={loadMoreButtonWrapperClass}>
					<Button
						type={ButtonType.Button}
						disabled={!hasNext}
						onClick={handleLoadNextOnClick}
						label="Mehr anzeigen"
					/>
				</div>
			)}

			<ConfirmDialog />
		</div>
	);
};
