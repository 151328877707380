import { Suspense } from "react";
import { BaseScreen } from "@components/base-screen";
import { NoAccess } from "@components/no-access";
import { UsersTable } from "@features/users/users-table";
import { useHasPermissions } from "@hooks/use-has-permissions";

export const UsersScreen = () => {
	const canRead = useHasPermissions(["UserInAccountPermission_AuthAdmin_Read"]);

	if (!canRead) return <NoAccess />;

	return (
		<BaseScreen title="Benutzerverwaltung">
			<Suspense fallback={<div>Lädt...</div>}>
				<UsersTable />
			</Suspense>
		</BaseScreen>
	);
};
