import { graphql } from "babel-plugin-relay/macro";

export const CREATE_TEXT_WITH_PAGES_ELEMENT_MUTATION = graphql`
	mutation createTextWithPagesElementButton_CreateTextWithPagesElementMutation(
		$input: CreateTextWithPagesElementInput!
	) {
		Admin {
			ElearningV2 {
				createTextWithPagesElement(input: $input) {
					eLearningContentNode {
						...contentCard_TreeNodeFragment
					}
				}
			}
		}
	}
`;
