import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { BaseScreen } from "@components/base-screen";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type academiesTags_Query } from "@relay/academiesTags_Query.graphql";
import { QUERY } from "./academies-tags.graphql";
import { AcademiesTagsTable } from "./parts/academies-tags-table";
import { NoAccess } from "../../components/no-access";

export const AcademiesTagsScreen = () => {
	const query = useLazyLoadQuery<academiesTags_Query>(QUERY, {});

	const canReadAndModify = useHasPermissions([
		"UserInAccountPermission_TagAdmin_Read",
		"UserInAccountPermission_TagAdmin_Modify",
	]);

	if (!canReadAndModify) {
		return <NoAccess />;
	}

	return (
		<BaseScreen>
			<h1>Schlagwörter</h1>
			<AcademiesTagsTable queryFragmentRef={query} />
		</BaseScreen>
	);
};
