import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import { useFragment, useMutation } from "react-relay";

import { persologElementQuestionsEditor_AddQuestionToPersologElementMutation } from "@relay/persologElementQuestionsEditor_AddQuestionToPersologElementMutation.graphql";
import { persologElementQuestionsEditor_EditQuestionFromPersologElementMutation } from "@relay/persologElementQuestionsEditor_EditQuestionFromPersologElementMutation.graphql";
import {
	persologElementQuestionsEditor_QuestionsFragment$data,
	persologElementQuestionsEditor_QuestionsFragment$key,
} from "@relay/persologElementQuestionsEditor_QuestionsFragment.graphql";

import { persologElementQuestionsEditor_RemoveQuestionFromPersologElementMutation } from "@relay/persologElementQuestionsEditor_RemoveQuestionFromPersologElementMutation.graphql";
import { PersologElementQuestionFormState } from "@screens/educational-offer/parts/persolog-element-question-form/persolog-element-question-form.types";
import {
	ADD_QUESTION_TO_PERSOLOG_ELEMENT_MUTATION,
	EDIT_QUESTION_FROM_PERSOLOG_ELEMENT_MUTATION,
	PERSOLOG_ELEMENT_FRAGMENT,
	REMOVE_QUESTION_FROM_PERSOLOG_ELEMENT_MUTATION,
} from "@screens/educational-offer/parts/persolog-element-questions-editor/persolog-element-questions-editor.graphql";
import { PersologElementQuestionsEditorProps } from "@screens/educational-offer/parts/persolog-element-questions-editor/persolog-element-questions-editor.types";
import { PagesEditor } from "src/features/pages-editor";
import { PersologElementQuestionFormDialog } from "src/screens/educational-offer/parts/persolog-element-question-form-dialog";

export const PersologElementQuestionsEditor = ({
	persologElementFragmentRef,
	onCreateQuestion,
	onDeleteQuestion,
}: PersologElementQuestionsEditorProps) => {
	const [isQuestionFormDialogVisible, setIsQuestionFormDialogVisible] = useState(false);
	const [selectedQuestion, setSelectedQuestion] = useState<
		persologElementQuestionsEditor_QuestionsFragment$data["questions"][number] | null
	>(null);

	const toast = useRef<Toast>(null);
	const persologElement = useFragment<persologElementQuestionsEditor_QuestionsFragment$key>(
		PERSOLOG_ELEMENT_FRAGMENT,
		persologElementFragmentRef,
	);
	const questions = persologElement ? [...persologElement.questions] : [];

	const [addQuestionToPersologElement] =
		useMutation<persologElementQuestionsEditor_AddQuestionToPersologElementMutation>(
			ADD_QUESTION_TO_PERSOLOG_ELEMENT_MUTATION,
		);

	const [editQuestionFromPersologElement] =
		useMutation<persologElementQuestionsEditor_EditQuestionFromPersologElementMutation>(
			EDIT_QUESTION_FROM_PERSOLOG_ELEMENT_MUTATION,
		);

	const [removeQuestionFromPersologElement] =
		useMutation<persologElementQuestionsEditor_RemoveQuestionFromPersologElementMutation>(
			REMOVE_QUESTION_FROM_PERSOLOG_ELEMENT_MUTATION,
		);

	const onCompleted = (summary: string, detail: string) => {
		toast.current?.show({
			severity: "success",
			life: 3000,
			summary: summary || "Erfolg",
			detail: detail || "Die Aktion wurde erfolgreich abgeschlossen",
		});
	};

	const onError = (summary: string, detail: string) => {
		toast.current?.show({
			severity: "error",
			life: 3000,
			summary: summary || "Fehlgeschlagen",
			detail: detail || "Die Aktion konnte nicht abgeschlossen werden",
		});
	};

	const handleOnCreateQuestion = (questionValues: PersologElementQuestionFormState) => {
		addQuestionToPersologElement({
			variables: {
				input: {
					elementId: persologElement!.id,
					title: questionValues.title,
					questionClasses: questionValues.questionClasses,
				},
			},
			onCompleted: () => {
				onCompleted("Erfolg", "Frage wurde erfolgreich erstellt");
				onCreateQuestion?.();
			},
			onError: () => onError("Fehlgeschlagen", "Frage konnte nicht erstellt werden"),
		});
	};

	const handleOnEditQuestion = (
		questionValues: PersologElementQuestionFormState,
		questionId: string,
	) => {
		editQuestionFromPersologElement({
			variables: {
				input: {
					elementId: persologElement!.id,
					questionId: questionId,
					newTitle: questionValues.title,
					newQuestionClasses: questionValues.questionClasses,
				},
			},
			onCompleted: () => onCompleted("Erfolg", "Frage wurde erfolgreich aktualisiert"),
			onError: () => onError("Fehlgeschlagen", "Frage konnte nicht aktualisiert werden"),
		});
	};

	const handleDeleteQuestion = (questionId: string) => {
		removeQuestionFromPersologElement({
			variables: {
				input: {
					elementId: persologElement!.id,
					questionId: questionId,
				},
			},
			onCompleted: () => {
				onCompleted("Erfolg", "Frage wurde erfolgreich gelöscht");
				onDeleteQuestion?.();
			},
			onError: () => onError("Fehlgeschlagen", "Frage konnte nicht gelöscht werden"),
		});
	};

	const handleQuestionFormOnSubmit = (questionValues: PersologElementQuestionFormState) => {
		selectedQuestion
			? handleOnEditQuestion(questionValues, selectedQuestion?.id)
			: handleOnCreateQuestion(questionValues);
		setIsQuestionFormDialogVisible(false);
		setSelectedQuestion(null);
	};

	const handleOnShowCreateQuestion = () => {
		setSelectedQuestion(null);
		setIsQuestionFormDialogVisible(true);
	};

	const handleOnShowEditQuestion = (pageId: string) => {
		const question = questions.find((page) => page.id === pageId);
		if (!question) return;

		setSelectedQuestion(question);
		setIsQuestionFormDialogVisible(true);
	};

	const handleOnHideQuestionFormDialog = () => {
		setSelectedQuestion(null);
		setIsQuestionFormDialogVisible(false);
	};

	const handleRearrangeQuestions = () => {
		// TODO: missing backend
	};

	return (
		<>
			<Toast ref={toast} />
			<PersologElementQuestionFormDialog
				isVisible={isQuestionFormDialogVisible}
				isCreating={selectedQuestion === null}
				persologElementFragmentRef={persologElement}
				questionFragmentRef={selectedQuestion}
				onSubmit={handleQuestionFormOnSubmit}
				onHide={handleOnHideQuestionFormDialog}
			/>
			<PagesEditor
				title={"Fragen"}
				createLabel={"Frage hinzufügen"}
				pages={questions}
				onShowCreatePage={handleOnShowCreateQuestion}
				onShowEditPage={handleOnShowEditQuestion}
				onDeletePage={handleDeleteQuestion}
				onRearrangePages={handleRearrangeQuestions}
			/>
		</>
	);
};
