import { css } from "@styled-system/css";

export const backButtonWrapper = css({
	mt: "2",
});

export const carouselClass = css({
	my: "16",
});

export const carouselItemClass = css({
	p: "16",
	borderColor: "shade.10",
	border: "solid 1px",
});

export const questionLengthErrorClass = css({
	color: "error.100",
	marginLeft: "80",
});
