import graphql from "babel-plugin-relay/macro";

export const SET_TREE_STATE_HEAD_MUTATION = graphql`
	mutation userRootNodeItemTreeItem_SetTreeStateHeadMutation($input: SetTreeStateHeadInput!) {
		Admin {
			LearnV2 {
				setTreeStateHead(input: $input) {
					treeState {
						...userRootNodeItem_TreeStateFragment
					}
				}
			}
		}
	}
`;
