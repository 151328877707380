import { graphql } from "babel-plugin-relay/macro";

export const PERSOLOG_CLASS_FRAGMENT = graphql`
	fragment persologClassForm_PersologClassFragment on Persolog_Class {
		title
		text
		classType
		image {
			id
			name
		}
	}
`;
