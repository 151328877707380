/**
 * @generated SignedSource<<07c7b6ad19ed02126b46a5c6b92763ee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DSAReportProblemType = "Accessibility" | "ConsumerProtection" | "Disinformation" | "IllegalContents" | "Other" | "PrivacyRequest" | "ProtectionOfMinors" | "TransparencyForOnlineAds" | "UserRightsAndComplaints";
export type DSAReportType = "Complaint" | "Feedback" | "Other" | "Report";
export type reportDetails_Query$variables = {
  reportId: string;
  skip: boolean;
};
export type reportDetails_Query$data = {
  readonly node?: {
    readonly createdAt?: string;
    readonly data?: {
      readonly email: string | null;
      readonly extra: string | null;
      readonly firstName: string | null;
      readonly lastName: string | null;
      readonly problemType: DSAReportProblemType;
      readonly reportType: DSAReportType;
      readonly url: string | null;
    };
    readonly id?: string;
    readonly referenceNumber?: string;
  } | null;
};
export type reportDetails_Query = {
  response: reportDetails_Query$data;
  variables: reportDetails_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "reportId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "reportId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "DSAReportData",
  "kind": "LinkedField",
  "name": "data",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "problemType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reportType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "extra",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "referenceNumber",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "reportDetails_Query",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "type": "DSAReport",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "reportDetails_Query",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "type": "DSAReport",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "d293d280a308e082a170b5bb9ab870a3",
    "id": null,
    "metadata": {},
    "name": "reportDetails_Query",
    "operationKind": "query",
    "text": "query reportDetails_Query(\n  $reportId: ID!\n  $skip: Boolean!\n) {\n  node(id: $reportId) @skip(if: $skip) {\n    __typename\n    ... on DSAReport {\n      id\n      data {\n        problemType\n        reportType\n        extra\n        lastName\n        firstName\n        email\n        url\n      }\n      referenceNumber\n      createdAt\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "0defbb06a0cd5c0c8e8e5e6034cbe6c9";

export default node;
