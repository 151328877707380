import { useFormik } from "formik";
import { Button } from "primereact/button";
import {
	type ExternalLicensePoolFilterProps,
	type ExternalLicensePoolFilterState,
} from "./external-license-pool-table-filters.interface";
import { DefaultTextFieldComponent } from "../../../components/DefaultTextInput";
import { ValidatedField } from "../../../components/ValidatedField";

export const ExternalLicensePoolFilters = ({ refetch }: ExternalLicensePoolFilterProps) => {
	const initialValues = {
		name: undefined,
	};

	const formik = useFormik<ExternalLicensePoolFilterState>({
		initialValues,
		onSubmit: (values: ExternalLicensePoolFilterState, { setSubmitting }) => {
			refetch(values.name);
			setSubmitting(false);
		},
	});

	const clearForm = () => {
		formik.handleReset(initialValues);
		formik.handleSubmit();
	};

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<div className="flex flex-row align-items-center">
				<ValidatedField<ExternalLicensePoolFilterState, string>
					name="name"
					label="Name"
					className="mr-2"
					component={DefaultTextFieldComponent}
					formikConfig={formik}
				/>
				<Button
					disabled={false}
					type="reset"
					onClick={clearForm}
					icon="pi pi-times"
					className="h-3rem ml-auto"
				/>
				<Button
					disabled={false}
					type="submit"
					icon="pi pi-search"
					className="h-3rem ml-2"
				/>
			</div>
		</form>
	);
};
