import graphql from "babel-plugin-relay/macro";

export const LEVEL_DEFINITION_FRAGMENT = graphql`
	fragment levelDefinitionForm_LevelDefinitionFragment on LevelDefinition {
		id
		title
		shortDescription
		text
		requiredPoints
		image {
			id
			name
			url
		}
	}
`;

export const EDIT_LEVEL_DEFINITION_MUTATION = graphql`
	mutation levelDefinitionForm_editLevelDefinitionMutation($input: EditLevelDefinitionInput!) {
		Admin {
			Gamification {
				editLevelDefinition(input: $input) {
					levelDefinition {
						...levelDefinitionForm_LevelDefinitionFragment
					}
				}
			}
		}
	}
`;
