import { ButtonType, EpicIcons, Icon } from "@thekeytechnology/epic-ui";
import { Suspense, useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { match } from "ts-pattern";
import { Button } from "@components/button";
import { ConfirmDialog } from "@components/confirm-dialog";
import { formatDateTime } from "@components/DateTimeDisplay";
import type { userRootNodeItem_CreateTreeForUserMutation } from "@relay/userRootNodeItem_CreateTreeForUserMutation.graphql";
import type { userRootNodeItem_LearnOpportunityV2Fragment$key } from "@relay/userRootNodeItem_LearnOpportunityV2Fragment.graphql";
import type { userRootNodeItem_RootNodesWithTreeStateTypeFragment$key } from "@relay/userRootNodeItem_RootNodesWithTreeStateTypeFragment.graphql";
import type { userRootNodeItem_TreeStateFragment$key } from "@relay/userRootNodeItem_TreeStateFragment.graphql";
import type { userRootNodeItem_UnlockTreeByAdminMutation } from "@relay/userRootNodeItem_UnlockTreeByAdminMutation.graphql";
import { Paths } from "@routes/paths";
import { error100, info100, success100 } from "@themes/colors";
import { H2Span, P2Span } from "@themes/font-tags";
import {
	TREE_STATE_FRAGMENT,
	CREATE_TREE_FOR_USER_MUTATION,
	LEARN_OPPORTUNITY_V2_FRAGMENT,
	UNLOCK_TREE_BY_ADMIN_MUTATION,
	ROOT_NODES_WITH_TREE_STATE_TYPE_FRAGMENT,
} from "./user-root-node-item.graphql";
import {
	contentHeaderClass,
	contentHeaderListClass,
	contentWrapperClass,
	expandButtonClass,
	headerClass,
	headerInnerClass,
	headerWrapperClass,
	lockStateClass,
	wrapperClass,
} from "./user-root-node-item.styles";
import { UserRootNodeItemProps } from "./user-root-node-item.types";
import { UserRootNodeItemTree } from "../user-root-node-item-tree";

export const UserRootNodeItem = ({
	userId,
	refetchList,
	rootNodeItemFragmentRef,
}: UserRootNodeItemProps) => {
	const [expanded, setExpanded] = useState(false);
	const [showConfirmUnlockDialog, setShowConfirmUnlockDialog] = useState(false);
	const [showConfirmUnlockPaywallDialog, setShowConfirmUnlockPaywallDialog] = useState(false);

	const item = useFragment<userRootNodeItem_RootNodesWithTreeStateTypeFragment$key>(
		ROOT_NODES_WITH_TREE_STATE_TYPE_FRAGMENT,
		rootNodeItemFragmentRef ?? null,
	);
	const rootNode = useFragment<userRootNodeItem_LearnOpportunityV2Fragment$key>(
		LEARN_OPPORTUNITY_V2_FRAGMENT,
		item?.rootNode ?? null,
	);
	const treeState = useFragment<userRootNodeItem_TreeStateFragment$key>(
		TREE_STATE_FRAGMENT,
		item?.treeState ?? null,
	);

	const [unlockTreeByAdmin, isUnlockingTree] =
		useMutation<userRootNodeItem_UnlockTreeByAdminMutation>(UNLOCK_TREE_BY_ADMIN_MUTATION);
	const [createTreeForUser, isCreatingTree] =
		useMutation<userRootNodeItem_CreateTreeForUserMutation>(CREATE_TREE_FOR_USER_MUTATION);

	const treeStatus = treeState?.definition?.status;

	const hasTreeState = treeState !== null;
	const unlockKind = rootNode?.structureDefinition.extension?.unlockInfo?.kind;
	const treeUnlockKind = treeState?.extension?.treeUnlockedState?.data.kind;
	const treeUnlockState = treeState?.extension?.treeUnlockedState;
	const hasPaywall = unlockKind !== "WallNotFound" && unlockKind !== "FullAccess";
	const paywallLocked = Boolean(
		hasTreeState && treeState?.extension?.treeUnlockedState?.data.kind === undefined,
	);

	const handleExpandOnClick = () => {
		setExpanded((prevExpanded) => !prevExpanded);
	};

	const handleCreateTreeOnClick = () => {
		setShowConfirmUnlockDialog(true);
	};

	const handleCreateTreeOnConfirm = () => {
		if (!item || !rootNode) return;

		createTreeForUser({
			variables: {
				input: {
					rootId: rootNode.id,
					userId,
				},
			},
			onCompleted: () => {
				toast.success("Kurs freigeschaltet!");
				refetchList(
					{},
					{
						fetchPolicy: "network-only",
					},
				);
			},
			onError: () => {
				toast.error("Fehler beim freischalten des Kurses!");
			},
		});

		setShowConfirmUnlockDialog(false);
	};

	const handleUnlockTreeOnClick = () => {
		setShowConfirmUnlockPaywallDialog(true);
	};

	const handleUnlockTreeOnConfirm = () => {
		if (!item || !rootNode) return;

		unlockTreeByAdmin({
			variables: {
				input: {
					rootId: rootNode.id,
					userId,
				},
			},
			onCompleted: () => {
				toast.success("Kurs freigeschaltet!");
			},
			onError: () => {
				toast.error("Fehler beim freischalten des Kurses!");
			},
		});

		setShowConfirmUnlockPaywallDialog(false);
	};

	const handleOnCancelConfirmUnlockPaywallDialog = () => {
		setShowConfirmUnlockPaywallDialog(false);
	};

	const handleOnCancelConfirmUnlockDialog = () => {
		setShowConfirmUnlockDialog(false);
	};

	const state = match({
		hasTreeState,
		paywallLocked,
		treeStatus,
	})
		.with(
			{
				hasTreeState: true,
				paywallLocked: true,
				treeStatus: "started",
			},
			() =>
				({
					icon: EpicIcons.LOCK_OPEN,
					variant: "unlocked",
					color: success100,
				}) as const,
		)
		.with(
			{
				hasTreeState: true,
				paywallLocked: false,
				treeStatus: "started",
			},
			() =>
				({
					icon: EpicIcons.LOCK_OPEN,
					variant: "paywallUnlocked",
					color: info100,
				}) as const,
		)
		.with(
			{
				hasTreeState: true,
				paywallLocked: false,
				treeStatus: "finished",
			},
			{
				hasTreeState: true,
				paywallLocked: true,
				treeStatus: "finished",
			},
			() =>
				({
					icon: EpicIcons.CHECK,
					variant: "finished",
					color: success100,
				}) as const,
		)
		.otherwise(
			() =>
				({
					icon: EpicIcons.LOCK,
					variant: "locked",
					color: error100,
				}) as const,
		);

	return (
		<>
			<ConfirmDialog
				title="Kurs freischalten"
				content={`Möchtest du den Kurs "${rootNode?.structureDefinition.title}" wirklich freischalten?`}
				onCancel={handleOnCancelConfirmUnlockDialog}
				onConfirm={handleCreateTreeOnConfirm}
				showDialog={showConfirmUnlockDialog}
				confirmButtonText="Ja, Freischalten"
			/>
			<ConfirmDialog
				title="Paywall entfernen"
				content={
					<div>
						Möchtest du die Paywall für den Kurs "{rootNode?.structureDefinition.title}"
						wirklich entfernen?
						<br />
						Diese Aktion kann nicht rückgängig gemacht werden.
					</div>
				}
				onCancel={handleOnCancelConfirmUnlockPaywallDialog}
				onConfirm={handleUnlockTreeOnConfirm}
				showDialog={showConfirmUnlockPaywallDialog}
				confirmButtonText="Ja, Paywall entfernen"
			/>
			<li className={wrapperClass}>
				<div className={headerWrapperClass}>
					<div className={headerClass}>
						<button
							type="button"
							disabled={!hasTreeState}
							className={expandButtonClass({
								disabled: !hasTreeState,
							})}
							onClick={handleExpandOnClick}
						>
							<Icon icon={expanded ? EpicIcons.ANGLE_DOWN : EpicIcons.ANGLE_RIGHT} />
						</button>
						<div className={headerInnerClass}>
							<div
								className={lockStateClass({
									state: state.variant,
								})}
							>
								<Icon icon={state.icon} color={state.color} />
							</div>
							<H2Span>{rootNode?.structureDefinition.title}</H2Span>
						</div>
					</div>
					{!hasTreeState && (
						<Button
							type={ButtonType.Button}
							icon={EpicIcons.UNLOCK}
							label="Kurs freischalten"
							loading={isCreatingTree}
							onClick={handleCreateTreeOnClick}
						/>
					)}
					{hasTreeState && hasPaywall && (
						<Button
							type={ButtonType.Button}
							icon={EpicIcons.DOLLAR}
							label="Paywall entfernen"
							loading={isUnlockingTree}
							onClick={handleUnlockTreeOnClick}
						/>
					)}
				</div>
				{expanded && (
					<div className={contentWrapperClass}>
						<div className={contentHeaderClass}>
							<ul className={contentHeaderListClass}>
								<li>
									<P2Span>
										Startdatum:{" "}
										<strong>
											{formatDateTime(treeState?.startedAt ?? "")}
										</strong>
									</P2Span>
								</li>
								<li>
									<P2Span>
										Fortschritt:{" "}
										<strong>{treeState?.progressPercentage}%</strong>
									</P2Span>
								</li>
								{treeUnlockKind === "UnlockedByAdmin" && (
									<li>
										<P2Span>
											Paywall entfernt von:{" "}
											<Link
												to={
													Paths.users.withId(
														treeUnlockState?.data.unlockedByUser?.id ??
															"",
													).edit.path
												}
											>
												<strong>
													{treeUnlockState?.data.unlockedByUser?.name}
												</strong>{" "}
											</Link>
											am{" "}
											<strong>
												{formatDateTime(treeUnlockState?.when ?? "")}
											</strong>
										</P2Span>
									</li>
								)}
								{treeUnlockKind === "UnlockedByLicense" && (
									<li>
										<P2Span>
											Paywall entfernt von: <strong>einer Lizenz</strong>{" "}
											erstellt am{" "}
											<strong>
												{formatDateTime(
													treeUnlockState?.data.license?.creationInfo
														?.createdAt ?? "",
												)}
											</strong>
										</P2Span>
									</li>
								)}
							</ul>
						</div>
						{rootNode && (
							<Suspense fallback={<div>Lade...</div>}>
								<UserRootNodeItemTree
									rootId={rootNode.id}
									userId={userId}
									hasPaywall={hasPaywall}
									submissions={treeState?.extension.submissions}
									currentHeadContentId={treeState?.definition.headContentNode?.id}
									firstContentCoordinatesIndexPath={
										rootNode?.structureDefinition.extension?.unlockInfo
											?.firstContentCoordinatesIndexPath
									}
								/>
							</Suspense>
						)}
					</div>
				)}
			</li>
		</>
	);
};
