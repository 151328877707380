import React, { useState } from "react";
import { useLazyLoadQuery } from "react-relay";
import { BaseScreen } from "@components/base-screen";
import { NoAccess } from "@components/no-access";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { videoSourcesScreen_Query } from "@relay/videoSourcesScreen_Query.graphql";
import { Wrapper } from "@screens/products/products.styles";
import { CreateVideoSourceButton } from "@screens/video-sources/parts/create-video-source-button";
import { VideoSourcesTable } from "@screens/video-sources/parts/video-sources-table";
import { VideoSourcesScreenContext } from "@screens/video-sources/video-sources.context";
import { QUERY } from "@screens/video-sources/video-sources.graphql";

export const VideoSourcesScreen = () => {
	const query = useLazyLoadQuery<videoSourcesScreen_Query>(QUERY, {});
	const [connectionId, setConnectionId] = useState("");

	const canRead = useHasPermissions(["UserInAccountPermission_VimeoVideoSourceAdmin_Read"]);

	if (!canRead) return <NoAccess />;

	return (
		<VideoSourcesScreenContext.Provider value={{ connectionId, setConnectionId }}>
			<BaseScreen>
				<h1>Video-Quellen</h1>
				<Wrapper>
					<CreateVideoSourceButton />
				</Wrapper>
				<VideoSourcesTable queryFragmentRef={query} />
			</BaseScreen>
		</VideoSourcesScreenContext.Provider>
	);
};
