import { EpicIcons } from "@thekeytechnology/epic-ui";
import { confirmDialog } from "primereact/confirmdialog";
import { useMutation } from "react-relay";
import { toast } from "react-toastify";
import { Button } from "@components/button";
import { resetPasswordButton_TriggerResetPasswordMutation } from "@relay/resetPasswordButton_TriggerResetPasswordMutation.graphql";
import { TRIGGER_RESET_PASSWORD_MUTATION } from "./reset-password-button.graphql";
import { ResetPasswordButtonProps } from "./reset-password-button.types";

export const ResetPasswordButton = ({ userId }: ResetPasswordButtonProps) => {
	const [triggerResetPassword, isResettingPassword] =
		useMutation<resetPasswordButton_TriggerResetPasswordMutation>(
			TRIGGER_RESET_PASSWORD_MUTATION,
		);

	const handleOnResetPasswordClick = () => {
		confirmDialog({
			header: "Passwort zurücksetzen",
			message: "Bist du sicher, dass du das Passwort zurücksetzen möchtest?",
			icon: "pi pi-info-circle",
			acceptClassName: "p-button-danger",
			acceptLabel: "Ja",
			rejectLabel: "Nein",
			accept: () =>
				triggerResetPassword({
					variables: {
						input: {
							userId,
						},
					},
					onCompleted: () => {
						toast.success("Passwort für Nutzer zurückgesetzt");
					},
				}),
		});
	};

	return (
		<>
			<Button
				icon={EpicIcons.KEY}
				label="Passwort zurücksetzen"
				onClick={handleOnResetPasswordClick}
				disabled={isResettingPassword}
			/>
		</>
	);
};
