import { readInlineData, useLazyLoadQuery } from "react-relay";
import type { userRootNodeItemTree_Query } from "@relay/userRootNodeItemTree_Query.graphql";
import type {
	userRootNodeItemTree_TreeNodeInlineFragment$data,
	userRootNodeItemTree_TreeNodeInlineFragment$key,
} from "@relay/userRootNodeItemTree_TreeNodeInlineFragment.graphql";
import { QUERY, TREE_NODE_INLINE_FRAGMENT } from "./user-root-node-item-tree.graphql";
import { treeWrapperClass, wrapperClass } from "./user-root-node-item-tree.styles";
import type { UserRootNodeItemTreeProps } from "./user-root-node-item-tree.types";
import { buildTree } from "./user-root-node-item-tree.utils";
import { UserRootNodeItemTreeItem } from "../user-root-node-item-tree-item";

export const UserRootNodeItemTree = ({
	rootId,
	userId,
	hasPaywall,
	submissions,
	currentHeadContentId,
	firstContentCoordinatesIndexPath,
}: UserRootNodeItemTreeProps) => {
	const query = useLazyLoadQuery<userRootNodeItemTree_Query>(QUERY, {
		rootId,
	});
	const tree = query.Admin.Tree.Tree;

	const nodes: userRootNodeItemTree_TreeNodeInlineFragment$data[] = tree.nodes.map((node) =>
		readInlineData<userRootNodeItemTree_TreeNodeInlineFragment$key>(
			TREE_NODE_INLINE_FRAGMENT,
			node,
		),
	);
	const treeRootNode = nodes.find((node) => node.id === tree.rootNode?.id);

	if (!treeRootNode) return null;
	const newTree = [
		buildTree({
			nodes,
			currentNode: treeRootNode,
			currentHeadContentId,
			firstContentCoordinatesIndexPath,
		}),
	];

	return (
		<>
			<div className={wrapperClass}>
				<div className={treeWrapperClass}>
					{newTree.map((item) => {
						return (
							<UserRootNodeItemTreeItem
								key={item.id}
								rootId={rootId}
								userId={userId}
								item={item}
								hasPaywall={hasPaywall}
								currentHeadContentId={currentHeadContentId}
								submissions={submissions}
							/>
						);
					})}
				</div>
			</div>
		</>
	);
};
