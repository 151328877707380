import { BaseScreen } from "@components/base-screen";
import { NoAccess } from "@components/no-access";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { ResetAllStagingDataButton } from "./parts/reset-all-staging-data-button";
import { ResetStagingBillingButton } from "./parts/reset-staging-billing-button";
import { ResetStagingUserProgressButton } from "./parts/reset-staging-user-progress-button";

export const StagingScreen = () => {
	const canRead = useHasPermissions("onlyOwnerOfRoot");

	if (!canRead) return <NoAccess />;

	return (
		<BaseScreen>
			<h1>Staging-Funktionen</h1>
			<div className="flex flex-column">
				<ResetAllStagingDataButton />
				<ResetStagingUserProgressButton />
				<ResetStagingBillingButton />
			</div>
		</BaseScreen>
	);
};
