import { css } from "@styled-system/css";
import { grid } from "@styled-system/patterns";

export const inputWrapperClass = grid({
	gridTemplateColumns: "[minmax(0,1fr) 4rem]",
});

export const videoDisplayWrapper = css({
	py: "16",
});

export const errorTextClass = css({
	color: "error.100",
});
