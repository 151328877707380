import { Suspense, useEffect } from "react";
import { useRefetchableFragment } from "react-relay";
import { userRootNodes_QueryFragment$key } from "@relay/userRootNodes_QueryFragment.graphql";
import { userRootNodes_RefetchQuery } from "@relay/userRootNodes_RefetchQuery.graphql";
import { QUERY_FRAGMENT } from "./user-root-nodes.graphql";
import { listClass } from "./user-root-nodes.styles";
import { type UserRootNodesProps } from "./user-root-nodes.types";
import { UserRootNodeItem } from "../user-root-node-item";
import { useUserRootNodesFilter } from "../user-root-nodes-filter";

export const UserRootNodes = ({ userId, queryFragmentRef }: UserRootNodesProps) => {
	const { filter } = useUserRootNodesFilter();
	const [query, refetch] = useRefetchableFragment<
		userRootNodes_RefetchQuery,
		userRootNodes_QueryFragment$key
	>(QUERY_FRAGMENT, queryFragmentRef ?? null);

	const nodes = query?.Admin.LearnV2.RootNodesWithTreeStatesForUser ?? [];

	useEffect(() => {
		refetch({
			name: filter,
		});
	}, [filter, refetch]);

	return (
		<Suspense fallback={<div>Lädt...</div>}>
			<ul className={listClass}>
				{nodes.map((node) => (
					<UserRootNodeItem
						key={node.rootNode?.id}
						refetchList={refetch}
						userId={userId}
						rootNodeItemFragmentRef={node}
					/>
				))}
			</ul>
		</Suspense>
	);
};
