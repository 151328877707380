import { graphql } from "babel-plugin-relay/macro";

export const PERSOLOG_ELEMENT_FRAGMENT = graphql`
	fragment persologElementQuestionFormDialog_PersologElementFragment on Persolog_PersologElement {
		...persologElementQuestionForm_PersologElementFragment
	}
`;
export const QUESTION_FRAGMENT = graphql`
	fragment persologElementQuestionFormDialog_QuestionFragment on Persolog_Question {
		title
		...persologElementQuestionForm_QuestionFragment
	}
`;
