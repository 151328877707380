import { useState } from "react";
import { useRefetchableFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { MultiSelect } from "primereact/multiselect";
import { ProductsSelect_QueryFragment$key } from "@relay/ProductsSelect_QueryFragment.graphql";
import { ProductsSelect_QueryFragmentRefetchQuery } from "@relay/ProductsSelect_QueryFragmentRefetchQuery.graphql";

const NUM_PRODUCTS_PER_PAGE = 20;

const QUERY_FRAGMENT = graphql`
	fragment ProductsSelect_QueryFragment on Query
	@refetchable(queryName: "ProductsSelect_QueryFragmentRefetchQuery")
	@argumentDefinitions(
		first: { type: "Int" }
		after: { type: "String", defaultValue: null }
		title: { type: "String", defaultValue: null }
		productIds: { type: "[ID!]!" }
		inKinds: { type: "[ProductKind!]", defaultValue: [License] }
	) {
		Admin {
			Billing {
				SearchProducts(first: $first, after: $after, titleOpt: $title, inKinds: $inKinds)
					@connection(key: "ProductsSelect_SearchProducts") {
					edges {
						cursor
						node {
							id
							title
						}
					}
				}
			}
		}
		nodes(ids: $productIds) {
			... on Product {
				id
				title
			}
		}
	}
`;

export interface Product {
	id: string;
	title: string;
}

interface OwnProps {
	fieldValue: string[];
	onChange: (products: string[]) => void;
	queryFragmentRef: ProductsSelect_QueryFragment$key;
}

export const ProductsSelect = ({ fieldValue, onChange, queryFragmentRef }: OwnProps) => {
	const [data, refetch] = useRefetchableFragment<
		ProductsSelect_QueryFragmentRefetchQuery,
		ProductsSelect_QueryFragment$key
	>(QUERY_FRAGMENT, queryFragmentRef);

	const products: Product[] = [
		...data.nodes,
		...(data?.Admin?.Billing.SearchProducts.edges?.map((e) => e?.node) || []),
	].filter(Boolean) as Product[];

	const uniqueProducts = products.filter(
		(obj, index) => products.findIndex((item) => item.id === obj.id) === index,
	);

	const [productsCache, setProductsCache] = useState<Product[]>(
		uniqueProducts.filter((product) => fieldValue.includes(product.id)),
	);

	const onFilter = (e: { filter: string }) => {
		refetch({
			title: e.filter,
			first: NUM_PRODUCTS_PER_PAGE,
			productIds: productsCache.map((p) => p.id),
		});
	};

	return (
		<MultiSelect
			dataKey="id"
			value={productsCache}
			options={uniqueProducts}
			optionLabel="title"
			onHide={() => onChange(productsCache?.map((l: Product) => l.id) || [])}
			onChange={(e) => setProductsCache(e.value)}
			filter
			resetFilterOnHide={true}
			filterPlaceholder="Produkte filtern"
			className="multiselect-custom"
			onFilter={onFilter}
			showSelectAll={false}
			emptyFilterMessage="Keine Produkte gefunden"
		/>
	);
};
