import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { type ProductPriceColumn_ProductFragment$key } from "@relay/ProductPriceColumn_ProductFragment.graphql";
import { CurrencyDisplay } from "../../../components/CurrencyDisplay";
// import { Taxrate } from "../../../components/taxrate";

const PRODUCT_FRAGMENT = graphql`
	fragment ProductPriceColumn_ProductFragment on Product {
		netPrice
		extensions {
			... on AcademiesProductExtension {
				kind
				deGrossPrice
			}
		}
		# price {
		# 	taxRatePercentage
		# }
	}
`;

type OwnProps = {
	productFragmentRef: ProductPriceColumn_ProductFragment$key;
};

export const ProductPriceColumn = ({ productFragmentRef }: OwnProps) => {
	const product = useFragment<ProductPriceColumn_ProductFragment$key>(
		PRODUCT_FRAGMENT,
		productFragmentRef,
	);
	const extension = product.extensions.find((e) => e.kind === "Academies");

	return (
		<div>
			<CurrencyDisplay value={extension?.deGrossPrice} />
			<CurrencyDisplay value={product.netPrice} />
			{/* <Taxrate taxrate={product.price.taxRatePercentage} /> */}
		</div>
	);
};
