import { Button } from "primereact/button";
import { useMutation } from "react-relay";
import { type deleteExternalLicensePoolButton_DeleteExternalLicensePoolMutation } from "@relay/deleteExternalLicensePoolButton_DeleteExternalLicensePoolMutation.graphql";
import { DELETE_EXTERNAL_LICENSE_POOL_MUTATION } from "./delete-external-license-pool-button.graphql";
import { type DeleteExternalLicensePoolButtonProps } from "./delete-external-license-pool-button.interface";

export const DeleteExternalLicensePoolButton = (props: DeleteExternalLicensePoolButtonProps) => {
	const [deleteExternalLicensePool] =
		useMutation<deleteExternalLicensePoolButton_DeleteExternalLicensePoolMutation>(
			DELETE_EXTERNAL_LICENSE_POOL_MUTATION,
		);

	const onClick = () =>
		deleteExternalLicensePool({
			variables: {
				input: {
					id: props.externalLicensePoolId,
				},
				connections: props.connections,
			},
		});

	return <Button onClick={onClick} icon="pi pi-trash" />;
};
