import { useFormik } from "formik";
import { useLazyLoadQuery } from "react-relay";
import { BaseScreen } from "@components/base-screen";
import { SelectSingleAccountFieldState } from "@features/accounts/select-single-account-field/select-single-account-field.types";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { SelectSingleAccountField } from "src/features/accounts/select-single-account-field";
import { QUERY } from "./licenses.graphql";
import { type LicensesScreenFormState } from "./licenses.types";
import { type licenses_LicensesQuery } from "../../__generated__/licenses_LicensesQuery.graphql";
import { NoAccess } from "../../components/no-access";
import { ValidatedField } from "../../components/ValidatedField";
import { LicensesTable } from "../../features/licenses/licenses-table";
import { Spacing32 } from "../../features/licenses/licenses-table/licenses-table.styles";

export const LicensesScreen = () => {
	const formik = useFormik<LicensesScreenFormState>({
		initialValues: {},
		onSubmit: () => {},
	});

	const data = useLazyLoadQuery<licenses_LicensesQuery>(
		QUERY,
		{},
		{ fetchPolicy: "store-or-network" },
	);

	const canRead = useHasPermissions(["UserInAccountPermission_LicenseAdmin_Read"]);

	if (!canRead) {
		return <NoAccess />;
	}

	return (
		<BaseScreen>
			<h1>Lizenzen</h1>
			<ValidatedField<LicensesScreenFormState, SelectSingleAccountFieldState>
				required
				name={"selectAccountFieldState"}
				label="Konto"
				placeholder="Konto auswählen..."
				formikConfig={formik}
				component={(renderConfig) => (
					<SelectSingleAccountField {...renderConfig} queryFragmentRef={data} />
				)}
			/>

			<Spacing32 />
			<LicensesTable
				licensesFragmentRef={data}
				showDeleteButton
				accountId={formik.values.selectAccountFieldState?.id}
				accountName={formik.values.selectAccountFieldState?.name}
			/>
		</BaseScreen>
	);
};
