import { ButtonType } from "@thekeytechnology/epic-ui";
import { useFormik } from "formik";
import { useFragment, useMutation } from "react-relay";
import { Button, ButtonVariant } from "@components/button";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { ValidatedField } from "@components/ValidatedField";
import { VideoSourceDropdown } from "@components/video-source-dropdown";
import { type videoElementForm_EditVideoElementMutation } from "@relay/videoElementForm_EditVideoElementMutation.graphql";
import { type videoElementForm_VideoElementFragment$key } from "@relay/videoElementForm_VideoElementFragment.graphql";
import { addEditedFormToEditedFormsArray, resetArrayOfEditedForms } from "@store/slices/CoreSlice";
import { useTypedDispatch } from "@store/store.redux";
import { VideoElementFormSchema } from "./video-element-form.consts";
import { EDIT_VIDEO_ELEMENT_MUTATION, VIDEO_ELEMENT_FRAGMENT } from "./video-element-form.graphql";
import { buttonWrapperClass, videoInputContainerClass } from "./video-element-form.styles";
import { VideoElementFormProps, VideoElementFormState } from "./video-element-form.types";
import { stripHtml } from "../../../../util/html.utils";
import { VideoSourceField } from "../video-source-field";

export const VideoElementForm = ({ videoElementFragmentRef, onBack }: VideoElementFormProps) => {
	const element = useFragment<videoElementForm_VideoElementFragment$key>(
		VIDEO_ELEMENT_FRAGMENT,
		videoElementFragmentRef,
	);
	const [editVideoElement, isEditingVideoElement] =
		useMutation<videoElementForm_EditVideoElementMutation>(EDIT_VIDEO_ELEMENT_MUTATION);

	const formId = "VideoElementForm";
	const dispatch = useTypedDispatch();
	const formik = useFormik<VideoElementFormState>({
		initialValues: {
			title: stripHtml(element.title),
			vimeoId: element.vimeoId,
			videoSourceId: element.vimeoVideoSource?.id!,
		},
		validationSchema: VideoElementFormSchema,
		onSubmit: (values) => {
			editVideoElement({
				variables: {
					input: {
						videoElementId: element.id,
						title: values.title,
						vimeoId: values.vimeoId ?? "",
						vimeoVideoSourceId: values.videoSourceId ?? "",
					},
				},
				onCompleted: () => {
					dispatch(resetArrayOfEditedForms());
				},
			});
		},
	});

	return (
		<form onSubmit={formik.handleSubmit}>
			<ValidatedField<VideoElementFormState, string>
				name={"title"}
				label={"Titel"}
				component={DefaultTextFieldComponent}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
				formikConfig={formik}
			/>
			<div className={videoInputContainerClass}>
				<ValidatedField<VideoElementFormState, string>
					name={"videoSourceId"}
					label={"Video-Quelle"}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					component={({ fieldValue, updateField, onChange }) => {
						return (
							<VideoSourceDropdown
								fieldValue={fieldValue}
								updateField={updateField}
								onChange={onChange}
							/>
						);
					}}
					formikConfig={formik}
				/>
				<ValidatedField<VideoElementFormState, string>
					name={"vimeoId"}
					label={"Video"}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					component={({ fieldValue, updateField, onChange, ...props }) => {
						return (
							<VideoSourceField
								onChange={onChange}
								onUpdate={updateField}
								vimeoId={fieldValue}
								videoSourceId={formik.values.videoSourceId}
								{...props}
							/>
						);
					}}
					formikConfig={formik}
				/>
			</div>
			<div className={buttonWrapperClass}>
				<Button
					disabled={isEditingVideoElement}
					type={ButtonType.Submit}
					variant={ButtonVariant.Strong}
					label="Speichern"
					stretch
				/>
				<Button
					type={ButtonType.Submit}
					onClick={onBack}
					label="Zurück"
					variant={ButtonVariant.Tertiary}
					stretch
				/>
			</div>
		</form>
	);
};
