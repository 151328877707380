import { BaseScreen } from "@components/base-screen";
import { MessageDispatchForm } from "@screens/message-dispatch-edit/parts/message-dispatch-form";
import { MessageDispatchFormContextProvider } from "@screens/message-dispatch-edit/parts/message-dispatch-form/message-dispatch-form.context";

export const MessageDispatchEditScreen = () => {
	return (
		<BaseScreen>
			<MessageDispatchFormContextProvider>
				<MessageDispatchForm />
			</MessageDispatchFormContextProvider>
		</BaseScreen>
	);
};
