import { cx } from "@styled-system/css";
import { contentContainerClass, wrapperClass } from "./base-screen-no-background.styles";
import type { BaseScreenNoBackgroundProps } from "./base-screen-no-background.types";

export const BaseScreenNoBackground = ({
	children,
	className,
	...props
}: BaseScreenNoBackgroundProps) => {
	return (
		<div className={wrapperClass}>
			<div className={cx(contentContainerClass, className)} {...props}>
				{children}
			</div>
		</div>
	);
};
