import { Dropdown, type DropdownChangeEvent } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { textWithPagesPageKindOptions } from "@components/text-with-pages-element-page-kind-dropdown/text-with-pages-element-page-kind-dropdown.consts";
import { type RenderConfig } from "@components/ValidatedField";
import { TextWithPagesPageDataKind } from "@relay/textWithPagesElementPagesEditor_PagesFragment.graphql";

export function TextWithPagesPageKindDropdown({
	fieldName,
	fieldValue,
	updateField,
	disabled,
	onChange,
	isValid,
	placeholder,
	required,
}: RenderConfig<TextWithPagesPageDataKind>) {
	const checkIfDropdownFieldChanged = (e: any) => {
		if (e.value !== fieldValue) {
			onChange?.();
		}
	};

	const handleOnChange = (dropdownChangeEvent: DropdownChangeEvent) => {
		updateField(dropdownChangeEvent.value);
		checkIfDropdownFieldChanged(dropdownChangeEvent.value);
	};
	return (
		<Dropdown
			options={textWithPagesPageKindOptions}
			name={fieldName}
			disabled={disabled}
			required={required}
			placeholder={placeholder}
			onChange={handleOnChange}
			value={fieldValue}
			className={classNames({ "p-invalid": !isValid })}
		/>
	);
}
