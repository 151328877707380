import { Paths } from "@routes/paths";
import { type RouteDefinition } from "@routes/route-definition";
import { LevelDefinitionEditRoutes } from "@screens/level-definition-edit";
import { LevelDefinitionScreen } from "./level-definition.screen";

export const LevelDefinitionRoutes: RouteDefinition[] = [
	{
		path: Paths.levelDefinition.path,
		element: <LevelDefinitionScreen />,
		requiredPermissions: ["UserInAccountPermission_LevelDefinitionAdmin_Read"],
	},
	...LevelDefinitionEditRoutes,
];
