import { EpicH1Span } from "@thekeytechnology/epic-ui";
import { useContext } from "react";
import { useLazyLoadQuery } from "react-relay";
import { BaseScreen } from "@components/base-screen";
import { type offers_Query } from "@relay/offers_Query.graphql";
import { OffersScreenContext } from "./offers.context";
import { QUERY } from "./offers.graphql";
import { OffersContextProvider } from "./offers.provider";
import { HeaderWrapper, Wrapper } from "./offers.styles";
import { CreateCourseLearnableButton } from "./parts/create-course-learnable-button";
import { OffersFilters } from "./parts/offers-filters";
import { OffersTable } from "./parts/offers-table";

export const OffersScreen = () => {
	const {
		filters: { ...filters },
	} = useContext(OffersScreenContext);

	const query = useLazyLoadQuery<offers_Query>(
		QUERY,
		{ ...filters },
		{ fetchPolicy: "network-only" },
	);

	return (
		<BaseScreen>
			<OffersContextProvider>
				<HeaderWrapper>
					<EpicH1Span>Angebote</EpicH1Span>
					<Wrapper>
						<CreateCourseLearnableButton />
						<OffersFilters queryFragmentRef={query} />
					</Wrapper>
				</HeaderWrapper>
				<OffersTable queryFragmentRef={query} />
			</OffersContextProvider>
		</BaseScreen>
	);
};
