import { Button, EpicIcons } from "@thekeytechnology/epic-ui";
import { useMutation } from "react-relay";

import { createPersologElementButton_CreatePersologElementMutation } from "@relay/createPersologElementButton_CreatePersologElementMutation.graphql";
import { CREATE_PERSOLOG_ELEMENT_MUTATION } from "@screens/educational-offer/parts/create-persolog-element-button/create-persolog-element-button.graphql";
import { CreatePersologElementButtonProps } from "@screens/educational-offer/parts/create-persolog-element-button/create-persolog-element-button.types";
import { persologClasses } from "@screens/educational-offer/parts/persolog-element-form/persolog-element-form.consts";

export const CreatePersologElementButton = ({
	eLearningContentNodeId,
}: CreatePersologElementButtonProps) => {
	const [createPersologElement, isCreatingPersologElement] =
		useMutation<createPersologElementButton_CreatePersologElementMutation>(
			CREATE_PERSOLOG_ELEMENT_MUTATION,
		);

	const handleCreatePersologElement = () => {
		createPersologElement({
			variables: {
				input: {
					title: "Neues Persolog-Element",
					eLearningContentId: eLearningContentNodeId,
					classes: persologClasses.map((persologClass) => ({
						title: persologClass.title,
						text: persologClass.text,
						classType: persologClass.classType,
						imageId: undefined,
					})),
				},
			},
		});
	};

	return (
		<Button
			disabled={isCreatingPersologElement}
			tooltip={`Persolog-Baustein anlegen`}
			icon={EpicIcons.USER}
			onClick={handleCreatePersologElement}
		/>
	);
};
