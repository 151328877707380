import { type PathParams } from "@thekeytechnology/epic-ui";
import { Card } from "primereact/card";
import { Suspense, useMemo } from "react";
import { useFragment, useLazyLoadQuery, useSubscription } from "react-relay";
import { useParams } from "react-router-dom";
import { type GraphQLSubscriptionConfig } from "relay-runtime";
import { BaseScreenNoBackground } from "@components/base-screen-no-background";
import { NoAccess } from "@components/no-access";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type educationalOffer_PublishingV2Fragment$key } from "@relay/educationalOffer_PublishingV2Fragment.graphql";
import { type educationalOffer_Query } from "@relay/educationalOffer_Query.graphql";
import { type educationalOffer_Subscription } from "@relay/educationalOffer_Subscription.graphql";
import { type educationalOffer_TreeFragment$key } from "@relay/educationalOffer_TreeFragment.graphql";
import {
	PUBLISHINGV2_FRAGMENT,
	QUERY,
	SUBSCRIPTION,
	TREE_FRAGMENT,
} from "./educational-offer.graphql";
import { type NodePath, type EducationalOfferPath } from "./educational-offer.paths";
import { type EducationalOfferScreenProps } from "./educational-offer.types";
import { EducationalOfferPublishing } from "./parts/educational-offer-publishing";
import { EducationalOfferTree } from "./parts/educational-offer-tree";
import { NodePart } from "./parts/node-part";
import { TreeSelectionContainer } from "./parts/TreeSelectionContext";

const EducationalOfferScreenComponent = ({ rootId }: EducationalOfferScreenProps) => {
	const { nodeId } = useParams<PathParams<typeof NodePath>>();

	const query = useLazyLoadQuery<educationalOffer_Query>(
		QUERY,
		{
			input: rootId,
		},
		{ fetchPolicy: "network-only" },
	);

	const tree = useFragment<educationalOffer_TreeFragment$key>(
		TREE_FRAGMENT,
		query.Admin.Tree.Tree,
	);

	const publishing = useFragment<educationalOffer_PublishingV2Fragment$key>(
		PUBLISHINGV2_FRAGMENT,
		query.Admin.PublishingV2.ActivePublishing ?? null,
	);

	const config = useMemo<GraphQLSubscriptionConfig<educationalOffer_Subscription>>(
		() => ({
			variables: {},
			subscription: SUBSCRIPTION,
		}),
		[],
	);

	useSubscription<educationalOffer_Subscription>(config);

	const canReadAndModify = useHasPermissions([
		"UserInAccountPermission_Nodes_ReadNodes",
		"UserInAccountPermission_Nodes_UpdateNodes",
	]);

	if (!canReadAndModify) return <NoAccess />;

	return (
		<TreeSelectionContainer treeId={rootId} initiallySelectedNodeId={nodeId ?? rootId}>
			<div className="grid flex-grow-1 surface-50">
				<div className="col-4 flex flex-column pl-4 mt-5">
					<EducationalOfferTree
						treeFragmentRef={tree}
						publishingV2FragmentRef={publishing}
					/>
				</div>
				<div className="col-8 flex">
					<Suspense
						fallback={
							<BaseScreenNoBackground>
								<Card>Lade...</Card>
							</BaseScreenNoBackground>
						}
					>
						{publishing && publishing.status !== "acknowledged" ? (
							<EducationalOfferPublishing publishingV2FragmentRef={publishing} />
						) : (
							<NodePart />
						)}
					</Suspense>
				</div>
			</div>
		</TreeSelectionContainer>
	);
};

export const EducationalOfferScreen = () => {
	const params = useParams<PathParams<typeof EducationalOfferPath>>();
	return params.rootId ? <EducationalOfferScreenComponent rootId={params.rootId} /> : null;
};
