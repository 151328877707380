import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query levelDefinition_Query(
		$first: Int
		$after: String
		$before: String
		$last: Int
		$filterByTitle: String
	) {
		...levelDefinitionTable_QueryFragment
			@arguments(
				first: $first
				after: $after
				before: $before
				last: $last
				filterByTitle: $filterByTitle
			)
	}
`;
