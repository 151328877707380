import { EpicIcons } from "@thekeytechnology/epic-ui";
import { Toast } from "primereact/toast";
import React, { useContext, useRef } from "react";
import { useFragment, useMutation } from "react-relay";
import { Button, ButtonVariant } from "@components/button";
import { deleteVideoSourceButton_DeleteVimeoVideoSourceMutation } from "@relay/deleteVideoSourceButton_DeleteVimeoVideoSourceMutation.graphql";
import { deleteVideoSourceButton_Vimeo_VimeoVideoSourceFragment$key } from "@relay/deleteVideoSourceButton_Vimeo_VimeoVideoSourceFragment.graphql";
import { VideoSourcesScreenContext } from "@screens/video-sources/video-sources.context";
import {
	DELETE_VIMEO_VIDEO_SOURCE_MUTATION,
	VIMEO_VIMEO_VIDEO_SOURCE_FRAGMENT,
} from "./delete-video-source-button.graphql";
import { DeleteVideoSourceButtonProps } from "./delete-video-source-button.types";

export const DeleteVideoSourceButton = ({
	videoSourceFragmentRef,
	onRefresh,
}: DeleteVideoSourceButtonProps) => {
	const toast = useRef<Toast>(null);

	const videoSource = useFragment<deleteVideoSourceButton_Vimeo_VimeoVideoSourceFragment$key>(
		VIMEO_VIMEO_VIDEO_SOURCE_FRAGMENT,
		videoSourceFragmentRef,
	);

	const { connectionId } = useContext(VideoSourcesScreenContext);
	const [deleteVideoSource, isDeletingVideoSource] =
		useMutation<deleteVideoSourceButton_DeleteVimeoVideoSourceMutation>(
			DELETE_VIMEO_VIDEO_SOURCE_MUTATION,
		);

	const handleDeleteVideoSource = () =>
		deleteVideoSource({
			variables: {
				input: {
					ids: [videoSource.id],
				},
				connections: [connectionId],
			},
			onCompleted: () => {
				toast.current?.show({
					severity: "success",
					life: 3000,
					summary: "Video-Quelle gelöscht",
					detail: "Die Video-Quelle wurde erfolgreich gelöscht",
				});
				onRefresh();
			},
		});

	return (
		<>
			<Toast ref={toast} />
			<Button
				icon={EpicIcons.TRASH}
				variant={ButtonVariant.Error}
				tooltip="Video-Quelle löschen"
				loading={isDeletingVideoSource}
				disabled={isDeletingVideoSource || videoSource.isDefault}
				onClick={handleDeleteVideoSource}
			/>
		</>
	);
};
