import { BaseScreen } from "@components/base-screen";
import { MessageDefinitionForm } from "@screens/message-definition-edit/parts/message-definition-form";

export const MessageDefinitionEditScreen = () => {
	return (
		<BaseScreen>
			<MessageDefinitionForm />
		</BaseScreen>
	);
};
