import { Paths } from "@routes/paths";
import { type RouteDefinition } from "@routes/route-definition";
import { LevelDefinitionEditScreen } from "./level-definition-edit.screen";

export const LevelDefinitionEditRoutes: RouteDefinition[] = [
	{
		path: Paths.levelDefinition.withIdPlaceholder().edit.path,
		element: <LevelDefinitionEditScreen />,
		requiredPermissions: [
			"UserInAccountPermission_LevelDefinitionAdmin_Read",
			"UserInAccountPermission_LevelDefinitionAdmin_Modify",
		],
	},
];
