import { useFormik } from "formik";
import { Button } from "primereact/button";
import React from "react";
import { useFragment } from "react-relay";
import { match } from "ts-pattern";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";

import { MemoizedRichTextEditor } from "@components/rich-text-editor";
import { TextWithPagesPageKindDropdown } from "@components/text-with-pages-element-page-kind-dropdown";
import { ValidatedField } from "@components/ValidatedField";
import { FileSelectionField, FileV2 } from "@features/files/file-selection-field";
import { textWithPagesElementPageForm_TextWithPagesPageFragment$key } from "@relay/textWithPagesElementPageForm_TextWithPagesPageFragment.graphql";
import { TextWithPagesPageDataKind } from "@relay/textWithPagesElementPagesEditor_PagesFragment.graphql";

import { TextWithPagesElementPageFormSchema } from "@screens/educational-offer/parts/text-with-pages-element-page-form/text-with-pages-element-page-form.consts";
import { TEXT_WITH_PAGES_PAGE_FRAGMENT } from "@screens/educational-offer/parts/text-with-pages-element-page-form/text-with-pages-element-page-form.graphql";
import { buttonWrapperClass } from "@screens/educational-offer/parts/text-with-pages-element-page-form/text-with-pages-element-page-form.styles";
import {
	TextWithPagesElementPageFormProps,
	TextWithPagesElementPageFormState,
} from "@screens/educational-offer/parts/text-with-pages-element-page-form/text-with-pages-element-page-form.types";

export const TextWithPagesElementPageForm = ({
	textWithPagesElementPageFragmentRef,
	onSubmit,
	isCreating,
}: TextWithPagesElementPageFormProps) => {
	const page = useFragment<textWithPagesElementPageForm_TextWithPagesPageFragment$key>(
		TEXT_WITH_PAGES_PAGE_FRAGMENT,
		textWithPagesElementPageFragmentRef ?? null,
	);

	const initialData = {
		internalTitle: page?.internalTitle ?? "",
		text: page?.data.text ?? "",
		image: {
			id: page?.data.image?.id ?? "",
			name: page?.data.image?.name ?? "",
		},
		kind: page?.data.kind ?? "TextPageData",
	};

	const formik = useFormik<TextWithPagesElementPageFormState>({
		enableReinitialize: false,
		initialValues: initialData,
		validationSchema: TextWithPagesElementPageFormSchema,
		onSubmit: (values) => {
			onSubmit?.(values);
		},
	});

	const displayPageDataInputField = (): JSX.Element => {
		return match(formik.values.kind as TextWithPagesPageDataKind)
			.with("TextPageData", () => (
				<ValidatedField<TextWithPagesElementPageFormState, string>
					name={"text"}
					label={"Text"}
					required
					formikConfig={formik}
					component={MemoizedRichTextEditor}
				/>
			))
			.with("ImagePageData", () => (
				<ValidatedField<TextWithPagesElementPageFormState, FileV2>
					name={"image"}
					label={"Bild"}
					required
					formikConfig={formik}
					component={({ fieldValue, updateField }) => (
						<FileSelectionField
							name={"element"}
							selectedFile={fieldValue}
							setSelectedFile={updateField}
							filterByFileTypes={["image/png", "image/jpg", "image/jpeg"]}
							canUploadFiles={true}
							canDeleteFiles={true}
						/>
					)}
				/>
			))
			.exhaustive();
	};

	return (
		<form onSubmit={formik.handleSubmit}>
			<ValidatedField<TextWithPagesElementPageFormState, TextWithPagesPageDataKind>
				disabled={!isCreating}
				name={"kind"}
				label={"Art der Seite"}
				required
				component={TextWithPagesPageKindDropdown}
				formikConfig={formik}
			/>
			<ValidatedField<TextWithPagesElementPageFormState, string>
				name={"internalTitle"}
				label={"Titel"}
				required
				component={DefaultTextFieldComponent}
				formikConfig={formik}
			/>
			{displayPageDataInputField()}
			<div className={buttonWrapperClass}>
				<Button type="submit" label="Speichern" />
			</div>
		</form>
	);
};
