import { Editor } from "@tinymce/tinymce-react";
import { memo, useRef } from "react";
import { type Editor as TinyMCEEditor } from "tinymce";
import { RichTextEditorToolbar, RichTextEditorToolbarsConfig } from "./rich-text-editor.const";
import { type RichTextEditorProps } from "./rich-text-editor.interface";

export const RichTextEditor = ({
	fieldValue,
	updateField,
	fieldName,
	disabled,
	onChange,
	toolbar = RichTextEditorToolbar.Default,
	height = 500,
}: RichTextEditorProps) => {
	const editorRef = useRef<TinyMCEEditor>(null);
	const onEditorChange = (a: string) => {
		updateField?.(a);
		onChange?.();
	};
	const idPrefix = "rich-text-editor-";
	const id = fieldName ? `${idPrefix}${fieldName}${Math.random()}` : undefined;

	const config = RichTextEditorToolbarsConfig[toolbar];

	return (
		<Editor
			tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
			onInit={(evt, editor) => {
				// @ts-expect-error
				if (editorRef.current) editorRef.current = editor;
			}}
			id={id}
			onEditorChange={onEditorChange}
			disabled={disabled}
			value={fieldValue}
			init={{
				menubar: false,
				block_formats: "Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3; Header 4=h4",
				height,
				image_caption: true,
				content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:16px }",
				...config,
			}}
		/>
	);
};

export const MemoizedRichTextEditorComponent = memo(RichTextEditor);
export const MemoizedRichTextEditor = (props: RichTextEditorProps) => (
	<MemoizedRichTextEditorComponent {...props} />
);
