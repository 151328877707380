import { graphql } from "babel-plugin-relay/macro";

export const PERSOLOG_ELEMENT_FRAGMENT = graphql`
	fragment persologElementQuestionForm_PersologElementFragment on Persolog_PersologElement {
		classes {
			classType
			title
		}
	}
`;
export const QUESTION_FRAGMENT = graphql`
	fragment persologElementQuestionForm_QuestionFragment on Persolog_Question {
		id
		title
		questionClasses {
			text
			classType
			...persologElementQuestionClassForm_PersologQuestionClassFragment
		}
	}
`;
