import { Dropdown, type DropdownChangeEvent } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { branchOptions } from "@components/branch-dropdown/branch-dropdown.consts";
import { type RenderConfig } from "@components/ValidatedField";
import type { Branch } from "@relay/editUserForm_EditMutation.graphql";

export function BranchDropdownComponent({
	fieldName,
	fieldValue,
	updateField,
	disabled,
	onChange,
	isValid,
	required,
	placeholder,
}: RenderConfig<Branch>) {
	const checkIfDropdownFieldChanged = (e: any) => {
		if (e.value !== fieldValue) {
			if (onChange) {
				onChange();
			}
		}
	};

	return (
		<Dropdown
			options={branchOptions}
			name={fieldName}
			disabled={disabled}
			required={required}
			placeholder={placeholder}
			onChange={(dropdownChangeEvent: DropdownChangeEvent) => {
				updateField(dropdownChangeEvent.value);
				checkIfDropdownFieldChanged(dropdownChangeEvent.value);
			}}
			value={fieldValue}
			className={classNames({ "p-invalid": !isValid })}
		/>
	);
}
