import { graphql } from "babel-plugin-relay/macro";

export const PERSOLOG_ELEMENT_FRAGMENT = graphql`
	fragment persologElementQuestionsEditor_QuestionsFragment on Persolog_PersologElement {
		id
		...persologElementQuestionFormDialog_PersologElementFragment
		questions {
			id
			title
			...persologElementQuestionFormDialog_QuestionFragment
		}
	}
`;

export const ADD_QUESTION_TO_PERSOLOG_ELEMENT_MUTATION = graphql`
	mutation persologElementQuestionsEditor_AddQuestionToPersologElementMutation(
		$input: AddQuestionToPersologElementInput!
	) {
		Admin {
			ElearningV2 {
				addQuestionToPersologElement(input: $input) {
					persologElement {
						...persologElementForm_PersologElementFragment
					}
				}
			}
		}
	}
`;
export const EDIT_QUESTION_FROM_PERSOLOG_ELEMENT_MUTATION = graphql`
	mutation persologElementQuestionsEditor_EditQuestionFromPersologElementMutation(
		$input: EditQuestionFromPersologElementInput!
	) {
		Admin {
			ElearningV2 {
				editQuestionFromPersologElement(input: $input) {
					persologElement {
						...persologElementForm_PersologElementFragment
					}
				}
			}
		}
	}
`;
export const REMOVE_QUESTION_FROM_PERSOLOG_ELEMENT_MUTATION = graphql`
	mutation persologElementQuestionsEditor_RemoveQuestionFromPersologElementMutation(
		$input: RemoveQuestionFromPersologElementInput!
	) {
		Admin {
			ElearningV2 {
				removeQuestionFromPersologElement(input: $input) {
					persologElement {
						...persologElementForm_PersologElementFragment
					}
				}
			}
		}
	}
`;
