import { useFormik } from "formik";
import React, { forwardRef, useImperativeHandle } from "react";
import { useFragment } from "react-relay";
import { DefaultTextAreaComponent } from "@components/DefaultTextInput";
import { ValidatedField } from "@components/ValidatedField";

import { persologElementQuestionClassForm_PersologQuestionClassFragment$key } from "@relay/persologElementQuestionClassForm_PersologQuestionClassFragment.graphql";
import { PersologElementQuestionClassFormSchema } from "@screens/educational-offer/parts/persolog-element-question-class-form/persolog-element-question-class-form.consts";
import { PERSOLOG_QUESTION_CLASS_FRAGMENT } from "@screens/educational-offer/parts/persolog-element-question-class-form/persolog-element-question-class-form.graphql";
import {
	PersologElementQuestionClassFormProps,
	PersologElementQuestionClassFormRef,
	PersologElementQuestionClassFormState,
} from "@screens/educational-offer/parts/persolog-element-question-class-form/persolog-element-question-class-form.types";

export const PersologElementQuestionClassForm = forwardRef<
	PersologElementQuestionClassFormRef,
	PersologElementQuestionClassFormProps
>(function PersologElementQuestionClassForm(
	{ persologElementQuestionClassFragmentRef, onSubmit, classType },
	ref,
) {
	const questionClass =
		useFragment<persologElementQuestionClassForm_PersologQuestionClassFragment$key>(
			PERSOLOG_QUESTION_CLASS_FRAGMENT,
			persologElementQuestionClassFragmentRef ?? null,
		);

	const initialValues: PersologElementQuestionClassFormState = {
		text: questionClass?.text ?? "",
		classType: classType,
	};

	const formik = useFormik<PersologElementQuestionClassFormState>({
		initialValues,
		validationSchema: PersologElementQuestionClassFormSchema,
		onSubmit: (values) => onSubmit?.(values),
	});

	useImperativeHandle(ref, () => ({
		classType: classType,
		values: formik.values,
		submit: formik.submitForm,
		validate: () => formik.validateForm().then((errors) => Object.keys(errors).length === 0),
	}));

	return (
		<ValidatedField<PersologElementQuestionClassFormState, string>
			name={"text"}
			label={"Antwort"}
			formikConfig={formik}
			required
			component={DefaultTextAreaComponent}
		/>
	);
});
