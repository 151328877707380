/**
 * @generated SignedSource<<a53521f95067348f579c3d7d462db082>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateAccountInput = {
  clientMutationId?: string | null;
  name: string;
};
export type accountEditScreen_CreateMutation$variables = {
  input: CreateAccountInput;
};
export type accountEditScreen_CreateMutation$data = {
  readonly Admin: {
    readonly Auth: {
      readonly createAccount: {
        readonly edge: {
          readonly node: {
            readonly id: string;
            readonly " $fragmentSpreads": FragmentRefs<"accountEditScreen_AccountFragment">;
          };
        };
      } | null;
    };
  };
};
export type accountEditScreen_CreateMutation = {
  response: accountEditScreen_CreateMutation$data;
  variables: accountEditScreen_CreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "accountEditScreen_CreateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "CreateAccountPayload",
                "kind": "LinkedField",
                "name": "createAccount",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Account",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "accountEditScreen_AccountFragment"
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "accountEditScreen_CreateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "CreateAccountPayload",
                "kind": "LinkedField",
                "name": "createAccount",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Account",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "registeredAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isDeleted",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AccountGroupAssociation",
                            "kind": "LinkedField",
                            "name": "groupAssociations",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AccountGroup",
                                "kind": "LinkedField",
                                "name": "group",
                                "plural": false,
                                "selections": (v4/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "accountExtensionKind",
                                "value": "Academies"
                              }
                            ],
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "extension",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": (v6/*: any*/),
                                    "concreteType": "UserInAccountConnection",
                                    "kind": "LinkedField",
                                    "name": "users",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "UserInAccountEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "UserInAccount",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v2/*: any*/),
                                              (v5/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "UserInAccountGroup",
                                                "kind": "LinkedField",
                                                "name": "groups",
                                                "plural": true,
                                                "selections": (v4/*: any*/),
                                                "storageKey": null
                                              },
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "User",
                                                "kind": "LinkedField",
                                                "name": "user",
                                                "plural": false,
                                                "selections": [
                                                  (v2/*: any*/),
                                                  (v3/*: any*/),
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "email",
                                                    "storageKey": null
                                                  },
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "activated",
                                                    "storageKey": null
                                                  }
                                                ],
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "cursor",
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "PageInfo",
                                        "kind": "LinkedField",
                                        "name": "pageInfo",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "endCursor",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "hasNextPage",
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      },
                                      {
                                        "kind": "ClientExtension",
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__id",
                                            "storageKey": null
                                          }
                                        ]
                                      }
                                    ],
                                    "storageKey": "users(first:20)"
                                  },
                                  {
                                    "alias": null,
                                    "args": (v6/*: any*/),
                                    "filters": null,
                                    "handle": "connection",
                                    "key": "AccountEditScreen_users",
                                    "kind": "LinkedHandle",
                                    "name": "users"
                                  }
                                ],
                                "type": "AcademiesAccountExtension",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": "extension(accountExtensionKind:\"Academies\")"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "68f2dbcb60f0322b6eda6e4dc8b5514a",
    "id": null,
    "metadata": {},
    "name": "accountEditScreen_CreateMutation",
    "operationKind": "mutation",
    "text": "mutation accountEditScreen_CreateMutation(\n  $input: CreateAccountInput!\n) {\n  Admin {\n    Auth {\n      createAccount(input: $input) {\n        edge {\n          node {\n            ...accountEditScreen_AccountFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment accountEditScreen_AccountFragment on Account {\n  name\n  id\n  registeredAt\n  isDeleted\n  groupAssociations {\n    group {\n      id\n      name\n    }\n  }\n  extension(accountExtensionKind: Academies) {\n    __typename\n    ... on AcademiesAccountExtension {\n      users(first: 20) {\n        edges {\n          node {\n            id\n            __typename\n          }\n          cursor\n        }\n        ...usersInAccountTable_UserInAccountConnectionFragment\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n  ...editAccountGroupsButton_AccountFragment\n}\n\nfragment editAccountGroupsButton_AccountFragment on Account {\n  ...editAccountGroupsForm_AccountFragment\n}\n\nfragment editAccountGroupsForm_AccountFragment on Account {\n  id\n  groupAssociations {\n    group {\n      id\n      name\n    }\n  }\n}\n\nfragment editUserInAccountGroup_UserInAccountFragment on UserInAccount {\n  groups {\n    id\n    name\n  }\n  user {\n    id\n  }\n}\n\nfragment usersInAccountTable_UserInAccountConnectionFragment on UserInAccountConnection {\n  edges {\n    node {\n      groups {\n        id\n        name\n      }\n      user {\n        id\n        name\n        email\n        activated\n      }\n      ...editUserInAccountGroup_UserInAccountFragment\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d2a4583e18bde0546add3ef3de3299b1";

export default node;
