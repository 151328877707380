import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query levelDefinitionEditScreen_Query($id: ID!, $skip: Boolean!) {
		node(id: $id) @skip(if: $skip) {
			... on LevelDefinition {
				id
				title
				...levelDefinitionForm_LevelDefinitionFragment
			}
		}
	}
`;
