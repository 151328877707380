import graphql from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment licensesTable_LicensesFragment on Query
	@refetchable(queryName: "licensesTable_Refetch")
	@argumentDefinitions(
		first: { type: "Int" }
		after: { type: "String" }
		nameOpt: { type: "String" }
	) {
		Admin {
			Auth {
				SelectAccounts(first: $first, after: $after, nameOpt: $nameOpt, ids: [])
					@connection(key: "licensesTable_SelectAccounts") {
					edges {
						node {
							...licensesTable_AccountFragment
								@arguments(first: $first, after: $after)
						}
					}
				}
			}
		}
		...createLicensesButton_QueryFragment
	}
`;
export const ACCOUNT_FRAGMENT = graphql`
	fragment licensesTable_AccountFragment on Account
	@inline
	@argumentDefinitions(first: { type: "Int" }, after: { type: "String" }) {
		id
		name
		extension(accountExtensionKind: Academies) {
			... on AcademiesAccountExtension {
				licenses(first: $first, after: $after) @connection(key: "licensesTable_licenses") {
					__id
					edges {
						node {
							...licensesTable_LicenseFragment
						}
					}
				}
			}
		}
	}
`;
export const LICENSE_FRAGMENT = graphql`
	fragment licensesTable_LicenseFragment on License @inline {
		id
		creationInfo {
			kind
			createdAt
			licenseDefinition {
				data {
					name
				}
			}
			... on LicenseCreatedByOrder {
				orderId
			}
			... on LicenseCreatedByAdmin {
				creatorId
			}
			... on LicenseCreatedByRegistration {
				userId
			}
		}
		usage {
			kind

			... on LicenseUsageUsedForTree {
				at
				forUser {
					email
				}
			}
		}
	}
`;
