import { css } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const wrapperClass = flex({
	justify: "center",
	align: "center",
	flexGrow: "1",
	paddingY: "20",
});

export const contentContainerCardClass = css({
	maxWidth: "[1920px]",
	height: "full",
	width: "full",
});
