/**
 * @generated SignedSource<<07863437405f185e5fabb8af7ffaf987>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddTextPageToTextWithPagesElementInput = {
  clientMutationId?: string | null;
  elementId: string;
  internalTitle: string;
  text: string;
};
export type useTextWithPagesElementPageMutations_AddTextPageMutation$variables = {
  input: AddTextPageToTextWithPagesElementInput;
};
export type useTextWithPagesElementPageMutations_AddTextPageMutation$data = {
  readonly Admin: {
    readonly ElearningV2: {
      readonly addTextPageToTextWithPagesElement: {
        readonly textWithPagesElement: {
          readonly " $fragmentSpreads": FragmentRefs<"textWithPagesElementForm_TextWithPagesElementFragment">;
        };
      } | null;
    };
  };
};
export type useTextWithPagesElementPageMutations_AddTextPageMutation = {
  response: useTextWithPagesElementPageMutations_AddTextPageMutation$data;
  variables: useTextWithPagesElementPageMutations_AddTextPageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useTextWithPagesElementPageMutations_AddTextPageMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AddTextPageToTextWithPagesElementPayload",
                "kind": "LinkedField",
                "name": "addTextPageToTextWithPagesElement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TextWithPagesElement",
                    "kind": "LinkedField",
                    "name": "textWithPagesElement",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "textWithPagesElementForm_TextWithPagesElementFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useTextWithPagesElementPageMutations_AddTextPageMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AddTextPageToTextWithPagesElementPayload",
                "kind": "LinkedField",
                "name": "addTextPageToTextWithPagesElement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TextWithPagesElement",
                    "kind": "LinkedField",
                    "name": "textWithPagesElement",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "task",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TextWithPagesPage",
                        "kind": "LinkedField",
                        "name": "pages",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "internalTitle",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "data",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "kind",
                                "storageKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "text",
                                    "storageKey": null
                                  }
                                ],
                                "type": "TextWithPagesTextPageData",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "File",
                                    "kind": "LinkedField",
                                    "name": "image",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "name",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "type": "TextWithPagesImagePageData",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f7f150660583703eddfea8c4167094c4",
    "id": null,
    "metadata": {},
    "name": "useTextWithPagesElementPageMutations_AddTextPageMutation",
    "operationKind": "mutation",
    "text": "mutation useTextWithPagesElementPageMutations_AddTextPageMutation(\n  $input: AddTextPageToTextWithPagesElementInput!\n) {\n  Admin {\n    ElearningV2 {\n      addTextPageToTextWithPagesElement(input: $input) {\n        textWithPagesElement {\n          ...textWithPagesElementForm_TextWithPagesElementFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment textWithPagesElementForm_TextWithPagesElementFragment on TextWithPagesElement {\n  id\n  title\n  task\n  ...textWithPagesElementPagesEditor_PagesFragment\n}\n\nfragment textWithPagesElementPageFormDialog_PageFragment on TextWithPagesPage {\n  internalTitle\n  ...textWithPagesElementPageForm_TextWithPagesPageFragment\n}\n\nfragment textWithPagesElementPageForm_TextWithPagesPageFragment on TextWithPagesPage {\n  internalTitle\n  data {\n    __typename\n    kind\n    ... on TextWithPagesTextPageData {\n      text\n    }\n    ... on TextWithPagesImagePageData {\n      image {\n        id\n        name\n      }\n    }\n  }\n}\n\nfragment textWithPagesElementPagesEditor_PagesFragment on TextWithPagesElement {\n  id\n  pages {\n    id\n    internalTitle\n    data {\n      __typename\n      kind\n    }\n    ...textWithPagesElementPageFormDialog_PageFragment\n  }\n}\n"
  }
};
})();

(node as any).hash = "c30f255a6a6d5c525799e37d5a2f6358";

export default node;
