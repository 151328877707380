// import { useContext } from "react";
import { useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "@components/button";
import { type createCourseLearnableButton_AcademiesCreateBranchRootMutation } from "@relay/createCourseLearnableButton_AcademiesCreateBranchRootMutation.graphql";
import { Paths } from "@routes/paths";
import { CREATE_MUTATION } from "./create-course-learnable-button.graphql";
import { type CreateCourseLearnableButtonProps } from "./create-course-learnable-button.types";
// import { OffersScreenContext } from "../../offers.context";

export const CreateCourseLearnableButton = ({ ...props }: CreateCourseLearnableButtonProps) => {
	const [create] =
		useMutation<createCourseLearnableButton_AcademiesCreateBranchRootMutation>(CREATE_MUTATION);
	const navigate = useNavigate();
	// const { connectionId } = useContext(OffersScreenContext);

	const handleOnClick = () => {
		create({
			variables: {
				input: {
					title: "UnpublishedCourseLearnable",
				},
				// connections: [connectionId],
			},
			onCompleted: (response) => {
				navigate(
					Paths.educationalOffer.withId(
						response.Admin.Tree.createBranchRoot?.createdRootNode?.node?.id ?? "",
					).path,
				);
				toast.success("Weiterbildung erstellt");
			},
			onError: () => {
				toast.error("Fehler beim erstellen einer Weiterbildung");
			},
		});
	};

	return <Button onClick={handleOnClick} label="Weiterbildung erstellen" {...props} />;
};
