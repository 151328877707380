import { graphql } from "babel-plugin-relay/macro";
import { Card } from "primereact/card";
import React, { useState } from "react";
import { useFragment, useMutation } from "react-relay";
import {
	type ConfigurationGraphqlInterface,
	type Config,
	type ConfigCustomLabel,
} from "./Config.interfaces";
import { ConfigDropdown, type ConfigDropdownOptions } from "./ConfigDropdown";
import { ConfigItem } from "./ConfigItem";
import { ConfigPreview } from "./ConfigPreview";
import { type StartTreeConfigEditor_RemoveStartTreeConfigMutation } from "../../../__generated__/StartTreeConfigEditor_RemoveStartTreeConfigMutation.graphql";
import {
	type StartTreeConfigEditor_TreeNodeFragment$key,
	type StartTreeConfigType,
} from "../../../__generated__/StartTreeConfigEditor_TreeNodeFragment.graphql";
import { formatDateTime } from "../../../components/DateTimeDisplay";

const TREE_NODE_FRAGMENT = graphql`
	fragment StartTreeConfigEditor_TreeNodeFragment on TreeNode {
		id
		structureDefinition {
			... on RootStructureDefinition {
				startConfigs {
					id
					configType
				}
			}
		}
	}
`;

const REMOVE_START_TREE_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation StartTreeConfigEditor_RemoveStartTreeConfigMutation(
		$input: RemoveStartTreeConfigInput!
	) {
		Admin {
			Tree {
				removeStartTreeConfig(input: $input) {
					rootNode {
						...StartTreeConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

type Props = {
	treeNodeFragmentRef: StartTreeConfigEditor_TreeNodeFragment$key;
};

export const StartTreeConfigEditor = ({ treeNodeFragmentRef }: Props) => {
	const treeNode = useFragment<StartTreeConfigEditor_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);
	const [removeStartTreeConfig, isRemovingStartTreeConfig] =
		useMutation<StartTreeConfigEditor_RemoveStartTreeConfigMutation>(
			REMOVE_START_TREE_CONDITION_CONFIGURATION_MUTATION,
		);

	const [previewOptions, setPreviewOptions] = useState<
		Array<ConfigDropdownOptions<StartTreeConfigType>>
	>([]);

	let labels: Array<ConfigCustomLabel<StartTreeConfigType>>;

	const configs: Array<Config<StartTreeConfigType, any | never>> = [];

	const options: Array<ConfigDropdownOptions<StartTreeConfigType>> = configs.map((c) => {
		return {
			label: c.configKey,
			value: c.configKey,
		};
	});

	const _setLabels = (customLabels: Array<ConfigCustomLabel<StartTreeConfigType>>) => {
		labels = customLabels;

		setPreviewOptions(
			configs.map((c) => {
				const selectedConfig = treeNode.structureDefinition.startConfigs?.find(
					(r: any) => r.configType === c.configKey,
				);
				const customLabel = labels.find((l) => l.configKey === c.configKey);

				if (selectedConfig && customLabel?.label) {
					return {
						label: `(${formatDateTime(customLabel.label)})`,
						value: c.configKey,
					};
				} else {
					return {
						label: c.configKey,
						value: c.configKey,
					};
				}
			}),
		);
	};

	return (
		<Card className="mb-2">
			<h2>Starten</h2>

			<ConfigDropdown<StartTreeConfigType>
				configOptions={options}
				onChange={(e) => {
					const selectedConfig = configs.find((c) => c.configKey === e.value);

					if (selectedConfig) {
						selectedConfig.addMutation({
							rootNodeId: treeNode.id,
							...selectedConfig.addMutationPayload,
						});
					}
				}}
				isPresentational={false}
			/>

			<ConfigPreview<ConfigurationGraphqlInterface<StartTreeConfigType>>
				selectedConfigs={treeNode.structureDefinition.startConfigs as any[]}
				template={(configuration: ConfigurationGraphqlInterface<StartTreeConfigType>) => (
					<>
						<ConfigItem<StartTreeConfigType, string>
							isPresentational={true}
							isLoading={isRemovingStartTreeConfig}
							configType={configuration.configType}
							canEdit={
								configs.find((c) => c.configKey === configuration.configType)
									?.editable as boolean
							}
							configOptions={previewOptions}
							onDelete={() => {
								removeStartTreeConfig({
									variables: {
										input: {
											rootNodeId: treeNode.id,
											configId: configuration.id,
										},
									},
								});
							}}
						/>
					</>
				)}
			/>
		</Card>
	);
};
