import type { PathParams } from "@thekeytechnology/epic-ui";
import { ProgressSpinner } from "primereact/progressspinner";
import { Suspense } from "react";
import { useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";
import type { levelDefinitionEditScreen_Query } from "@relay/levelDefinitionEditScreen_Query.graphql";
import { BaseScreen } from "@components/base-screen";
import { LevelDefinitionPath } from "@screens/level-definition/level-definition.paths";
import { H1Span, H4Span } from "@themes/font-tags";
import { QUERY } from "./level-definition-edit.graphql";
import { LevelDefintionForm } from "./parts/level-definition-form";

export const LevelDefinitionEditScreen = () => {
	const { levelId } = useParams<PathParams<typeof LevelDefinitionPath>>();

	const query = useLazyLoadQuery<levelDefinitionEditScreen_Query>(QUERY, {
		id: levelId!,
		skip: !levelId,
	});

	if (!query.node) {
		return (
			<H4Span>
				Fehler beim Laden des Levels
				<br />
				<code>Level-ID: {levelId}</code>
			</H4Span>
		);
	}

	return (
		<BaseScreen>
			<H1Span>Level-Definiton bearbeiten: {query.node.title}</H1Span>
			<Suspense fallback={<ProgressSpinner />}>
				<LevelDefintionForm levelDefinitionFragmentRef={query.node} />
			</Suspense>
		</BaseScreen>
	);
};
