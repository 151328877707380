/**
 * @generated SignedSource<<a0e545146a142b4709936fc45785d0f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type persologElementQuestionClassForm_PersologQuestionFragment$data = {
  readonly title: string;
  readonly " $fragmentType": "persologElementQuestionClassForm_PersologQuestionFragment";
};
export type persologElementQuestionClassForm_PersologQuestionFragment$key = {
  readonly " $data"?: persologElementQuestionClassForm_PersologQuestionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"persologElementQuestionClassForm_PersologQuestionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "persologElementQuestionClassForm_PersologQuestionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "Persolog_Question",
  "abstractKey": null
};

(node as any).hash = "4f5e1a9f6b19c4474f82f13c7fffc544";

export default node;
