import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { BaseScreen } from "@components/base-screen";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type instructorsScreen_InstructorsQuery } from "@relay/instructorsScreen_InstructorsQuery.graphql";
import { NoAccess } from "../../components/no-access";
import { InstructorsTable } from "../../features/instructors/instructors-table/instructors-table.component";

export const InstructorsScreen = () => {
	const data = useLazyLoadQuery<instructorsScreen_InstructorsQuery>(
		graphql`
			query instructorsScreen_InstructorsQuery {
				...instructorsTable_InstructorsListFragment
			}
		`,
		{},
		{ fetchPolicy: "network-only" },
	);

	const canRead = useHasPermissions(["UserInAccountPermission_Instructors_ReadInstructors"]);

	if (!canRead) return <NoAccess />;

	return (
		<BaseScreen>
			<h1>Trainer:innen</h1>
			<InstructorsTable instructorsFragmentRef={data} />
		</BaseScreen>
	);
};
