import { useFragment } from "react-relay";
import { type videoSourcesTableActionColumn_Vimeo_VimeoVideoSourceFragment$key } from "@relay/videoSourcesTableActionColumn_Vimeo_VimeoVideoSourceFragment.graphql";
import { VIDEO_SOURCE_VIDEO_SOURCE_FRAGMENT } from "./video-sources-table-action-column.graphql";
import { wrapperClass } from "./video-sources-table-action-column.styles";
import { VideoSourcesTableActionColumnProps } from "./video-sources-table-action-column.types";
import { DeleteVideoSourceButton } from "../delete-video-source-button";
import { EditVideoSourceButton } from "../edit-video-source-button";
import { SetVideoSourceAsDefaultButton } from "../set-video-source-as-default-button";

export const VideoSourcesTableActionColumn = ({
	videoSourceFragmentRef,
	onRefresh,
}: VideoSourcesTableActionColumnProps) => {
	const videoSource =
		useFragment<videoSourcesTableActionColumn_Vimeo_VimeoVideoSourceFragment$key>(
			VIDEO_SOURCE_VIDEO_SOURCE_FRAGMENT,
			videoSourceFragmentRef,
		);

	return (
		<div className={wrapperClass}>
			<EditVideoSourceButton videoSourceFragmentRef={videoSource} />
			<SetVideoSourceAsDefaultButton
				videoSourceFragmentRef={videoSource}
				onRefresh={onRefresh}
			/>
			<DeleteVideoSourceButton videoSourceFragmentRef={videoSource} onRefresh={onRefresh} />
		</div>
	);
};
