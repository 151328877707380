import { css } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const fieldWrapperClass = flex({
	gap: "8",
	direction: "row",
	alignItems: "flex-end",
});

export const inputFieldClass = css({
	width: "[25rem]",
});

export const buttonWrapperClass = flex({
	gap: "4",
	align: "center",
	marginBottom: "16",
});
