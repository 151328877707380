/**
 * @generated SignedSource<<956534803f34362cb707acb38011ddc1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Persolog_ClassType = "D" | "G" | "I" | "S";
import { FragmentRefs } from "relay-runtime";
export type persologElementForm_PersologElementFragment$data = {
  readonly classes: ReadonlyArray<{
    readonly classType: Persolog_ClassType;
    readonly image: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly text: string;
    readonly title: string;
    readonly " $fragmentSpreads": FragmentRefs<"persologClassForm_PersologClassFragment">;
  }>;
  readonly id: string;
  readonly questions: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"persologElementQuestionsEditor_QuestionsFragment">;
  readonly " $fragmentType": "persologElementForm_PersologElementFragment";
};
export type persologElementForm_PersologElementFragment$key = {
  readonly " $data"?: persologElementForm_PersologElementFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"persologElementForm_PersologElementFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "persologElementForm_PersologElementFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Persolog_Class",
      "kind": "LinkedField",
      "name": "classes",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "classType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "image",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "persologClassForm_PersologClassFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Persolog_Question",
      "kind": "LinkedField",
      "name": "questions",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "persologElementQuestionsEditor_QuestionsFragment"
    }
  ],
  "type": "Persolog_PersologElement",
  "abstractKey": null
};
})();

(node as any).hash = "ed618c63a1e5bf72a7b69a4b21dd5c75";

export default node;
