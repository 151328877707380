import { css } from "@styled-system/css";

export const listClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "8",
	height: "[32rem]",
	maxHeight: "[32rem]",
	overflowY: "scroll",
});
