import { Button } from "primereact/button";
import { useFragment } from "react-relay";
import { PersologElementForm } from "@screens/educational-offer/parts/persolog-element-form/persolog-element-form.component";
import { TextElementForm } from "@screens/educational-offer/parts/text-element-form";
import { TextWithPagesElementForm } from "@screens/educational-offer/parts/text-with-pages-element-form";
import { ELEMENTV2_FRAGMENT } from "./element-form.graphql";
import { type ElementFormProps } from "./element-form.types";
import { ImageFileTypeOptions } from "../../../../features/files/file-filters/file-filters.const";
import { selectArrayOfEditedForms } from "../../../../store/slices/CoreSlice";
import { useTypedSelector } from "../../../../store/store.redux";
import { htmlTagsInTextRemover } from "../../../../util/htmlTagsInTextRemover";
import { ClozeTextElementForm } from "../cloze-text-element-form";
import { EnhancedTextElementForm } from "../enhanced-text-element-form";
import { FileElementForm } from "../file-element-form";
import { MarkMistakeElementForm } from "../MarkMistakeElementForm";
import { MatrixElementForm } from "../matrix-element-form";
import { MultipleChoiceElementForm } from "../multiple-choice-element-form/multiple-choice-element-form.component";
import { OrderElementForm } from "../order-element-form";
import { PodcastWithTimestampElementForm } from "../podcast-with-timestamp-element-form";
import { VideoElementForm } from "../video-element-form";

export const ElementForm = ({ elementFragmentRef, onBack }: ElementFormProps) => {
	const element = useFragment(ELEMENTV2_FRAGMENT, elementFragmentRef);

	const arrayOfEditedForms = useTypedSelector(selectArrayOfEditedForms);

	const Form = (() => {
		switch (element.elementType) {
			case "file":
				return (
					<FileElementForm
						fileElementV2FragmentRef={element}
						onBack={onBack}
						fileTypeOptions={ImageFileTypeOptions}
					/>
				);
			case "video":
				return <VideoElementForm videoElementFragmentRef={element} onBack={onBack} />;
			case "multipleChoice":
				return (
					<MultipleChoiceElementForm
						multipleChoiceElementFragmentRef={element}
						onBack={onBack}
					/>
				);
			case "markMistakes":
				return (
					<MarkMistakeElementForm
						onBack={onBack}
						markMistakeElementFragmentRef={element}
					/>
				);
			case "podcastWithTimestamp":
				return (
					<PodcastWithTimestampElementForm
						podcastWithTimestampElementFragmentRef={element}
						onBack={onBack}
					/>
				);
			case "matrix":
				return <MatrixElementForm matrixElementFragmentRef={element} onBack={onBack} />;
			case "enhancedText":
				return (
					<EnhancedTextElementForm
						enhancedTextElementFragmentRef={element}
						onBack={onBack}
					/>
				);
			case "order":
				return <OrderElementForm orderElementFragmentRef={element} onBack={onBack} />;
			case "clozeText":
				return (
					<ClozeTextElementForm clozeTextElementFragmentRef={element} onBack={onBack} />
				);
			case "text":
				return <TextElementForm textElementFragmentRef={element} onBack={onBack} />;
			case "textWithPages":
				return (
					<TextWithPagesElementForm
						textWithPagesElementFragmentRef={element}
						onBack={onBack}
					/>
				);
			case "persolog":
				return <PersologElementForm persologElementFragmentRef={element} onBack={onBack} />;
			default:
				return null;
		}
	})();
	return (
		<div>
			<div className="flex align-items-center">
				<h2>Bearbeite Element {htmlTagsInTextRemover(element.title)}</h2>
				<Button
					disabled={arrayOfEditedForms.length > 0}
					className="ml-auto"
					icon="pi pi-times"
					onClick={() => {
						onBack();
					}}
				/>
			</div>
			{Form}
		</div>
	);
};
