import { TextWithPagesPageDataKind } from "@relay/textWithPagesElementPagesEditor_PagesFragment.graphql";
import { useTextWithPagesElementPageMutations_AddImagePageMutation } from "@relay/useTextWithPagesElementPageMutations_AddImagePageMutation.graphql";
import { useTextWithPagesElementPageMutations_AddTextPageMutation } from "@relay/useTextWithPagesElementPageMutations_AddTextPageMutation.graphql";
import { useTextWithPagesElementPageMutations_DeletePageMutation } from "@relay/useTextWithPagesElementPageMutations_DeletePageMutation.graphql";
import { useTextWithPagesElementPageMutations_EditImagePageMutation } from "@relay/useTextWithPagesElementPageMutations_EditImagePageMutation.graphql";
import { useTextWithPagesElementPageMutations_EditTextPageMutation } from "@relay/useTextWithPagesElementPageMutations_EditTextPageMutation.graphql";
import { useTextWithPagesElementPageMutations_RearrangePagesMutation } from "@relay/useTextWithPagesElementPageMutations_RearrangePagesMutation.graphql";
import { useMutation } from "react-relay";
import { match } from "ts-pattern";
import {
	ADD_IMAGE_PAGE_MUTATION,
	ADD_TEXT_PAGE_MUTATION,
	DELETE_PAGE_MUTATION,
	EDIT_IMAGE_PAGE_MUTATION,
	EDIT_TEXT_PAGE_MUTATION,
	REARRANGE_PAGES_MUTATION,
} from "@hooks/use-text-with-pages-element-page-mutations/use-text-with-pages-element-page-mutations.graphql";
import { useTextWithPagesElementPageProps } from "@hooks/use-text-with-pages-element-page-mutations/use-text-with-pages-element-page-mutations.types";
import { TextWithPagesElementPageFormState } from "@screens/educational-offer/parts/text-with-pages-element-page-form/text-with-pages-element-page-form.types";

export const useTextWithPagesElementPageMutations = ({
	textWithPagesElementId,
	toast,
}: useTextWithPagesElementPageProps) => {
	const [addTextPageToTextWithPagesElement] =
		useMutation<useTextWithPagesElementPageMutations_AddTextPageMutation>(
			ADD_TEXT_PAGE_MUTATION,
		);
	const [addImagePageToTextWithPagesElement] =
		useMutation<useTextWithPagesElementPageMutations_AddImagePageMutation>(
			ADD_IMAGE_PAGE_MUTATION,
		);
	const [editTextPageFromTextWithPagesElement] =
		useMutation<useTextWithPagesElementPageMutations_EditTextPageMutation>(
			EDIT_TEXT_PAGE_MUTATION,
		);
	const [editImagePageFromTextWithPagesElement] =
		useMutation<useTextWithPagesElementPageMutations_EditImagePageMutation>(
			EDIT_IMAGE_PAGE_MUTATION,
		);
	const [removePageFromTextWithPagesElement] =
		useMutation<useTextWithPagesElementPageMutations_DeletePageMutation>(DELETE_PAGE_MUTATION);
	const [rearrangePages] =
		useMutation<useTextWithPagesElementPageMutations_RearrangePagesMutation>(
			REARRANGE_PAGES_MUTATION,
		);

	const onCompleted = (summary: string, detail: string) => {
		toast.current?.show({
			severity: "success",
			life: 3000,
			summary: summary || "Erfolg",
			detail: detail || "Die Aktion wurde erfolgreich abgeschlossen",
		});
	};

	const onError = (summary: string, detail: string) => {
		toast.current?.show({
			severity: "error",
			life: 3000,
			summary: summary || "Fehlgeschlagen",
			detail: detail || "Die Aktion konnte nicht abgeschlossen werden",
		});
	};

	const handleOnCreatePage = async (pageValues: TextWithPagesElementPageFormState) => {
		if (!textWithPagesElementId) return;
		match(pageValues.kind as TextWithPagesPageDataKind)
			.with("TextPageData", () => {
				addTextPageToTextWithPagesElement({
					variables: {
						input: {
							elementId: textWithPagesElementId,
							internalTitle: pageValues.internalTitle,
							text: pageValues.text!,
						},
					},
					onCompleted: () =>
						onCompleted("Seite erstellt", "Die Seite wurde erfolgreich erstellt"),
					onError: () =>
						onError("Seite nicht erstellt", "Die Seite konnte nicht erstellt werden"),
				});
			})
			.with("ImagePageData", () => {
				addImagePageToTextWithPagesElement({
					variables: {
						input: {
							elementId: textWithPagesElementId,
							internalTitle: pageValues.internalTitle,
							imageId: pageValues.image.id!,
						},
					},
					onCompleted: () => {},
					onError: () =>
						onError(
							"Seite nicht erstellt",
							"Die Seite mit Button konnte nicht erstellt werden",
						),
				});
			})
			.exhaustive();
	};

	const handleOnEditPage = async (
		pageValues: TextWithPagesElementPageFormState,
		pageId: string,
	) => {
		if (!textWithPagesElementId || !pageId) return;
		match(pageValues.kind as TextWithPagesPageDataKind)
			.with("TextPageData", () => {
				editTextPageFromTextWithPagesElement({
					variables: {
						input: {
							elementId: textWithPagesElementId,
							pageId: pageId,
							newInternalTitle: pageValues.internalTitle,
							newText: pageValues.text!,
						},
					},
					onCompleted: () =>
						onCompleted("Seite bearbeitet", "Die Seite wurde erfolgreich bearbeitet"),
					onError: () =>
						onError(
							"Seite nicht bearbeitet",
							"Die Seite konnte nicht bearbeitet werden",
						),
				});
			})
			.with("ImagePageData", () => {
				editImagePageFromTextWithPagesElement({
					variables: {
						input: {
							elementId: textWithPagesElementId,
							pageId: pageId,
							newInternalTitle: pageValues.internalTitle,
							newImageId: pageValues.image.id!,
						},
					},
					onCompleted: () =>
						onCompleted("Seite bearbeitet", "Die Seite wurde erfolgreich bearbeitet"),
					onError: () =>
						onError(
							"Seite nicht bearbeitet",
							"Die Seite konnte nicht bearbeitet werden",
						),
				});
			})
			.exhaustive();
	};

	const handleDeletePage = (pageId: string) => {
		if (!textWithPagesElementId) return;
		removePageFromTextWithPagesElement({
			variables: {
				input: {
					elementId: textWithPagesElementId,
					pageId: pageId,
				},
			},
			onCompleted: () =>
				onCompleted("Seite gelöscht", "Die Seite wurde erfolgreich gelöscht"),
			onError: () =>
				onError("Seite nicht gelöscht", "Die Seite konnte nicht gelöscht werden"),
		});
	};

	const handleRearrangePages = (rearrangedPageIds: string[]) => {
		if (!textWithPagesElementId) return;
		rearrangePages({
			variables: {
				input: {
					elementId: textWithPagesElementId,
					pageIds: rearrangedPageIds,
				},
			},
			onCompleted: () =>
				onCompleted(
					"Seiten-Reihenfolge gespeichert",
					"Die Seiten-Reihenfolge wurde erfolgreich gespeichert",
				),
			onError: () =>
				onError(
					"Seiten-Reihenfolge nicht gespeichert",
					"Die Seiten-Reihenfolge konnte nicht gespeichert werden",
				),
		});
	};

	return {
		handleOnCreatePage,
		handleOnEditPage,
		handleDeletePage,
		handleRearrangePages,
	};
};
