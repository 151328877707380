import { Dialog } from "@thekeytechnology/epic-ui";
import { useFragment } from "react-relay";
import { textWithPagesElementPageFormDialog_PageFragment$key } from "@relay/textWithPagesElementPageFormDialog_PageFragment.graphql";
import { TextWithPagesElementPageForm } from "@screens/educational-offer/parts/text-with-pages-element-page-form";
import { PAGE_FRAGMENT } from "@screens/educational-offer/parts/text-with-pages-element-page-form-dialog/text-with-pages-element-page-form-dialog.graphql";
import { TextWithPagesElementPageFormDialogProps } from "@screens/educational-offer/parts/text-with-pages-element-page-form-dialog/text-with-pages-element-page-form-dialog.types";

export const TextWithPagesElementPageFormDialog = ({
	textWithPagesElementPageFragmentRef,
	onHide,
	onSubmit,
	isVisible,
}: TextWithPagesElementPageFormDialogProps) => {
	const page = useFragment<textWithPagesElementPageFormDialog_PageFragment$key>(
		PAGE_FRAGMENT,
		textWithPagesElementPageFragmentRef ?? null,
	);

	const dialogTitle = page ? `Seite ${page.internalTitle} bearbeiten` : "Seite erstellen";

	return (
		<Dialog visible={isVisible} onHide={onHide} title={dialogTitle}>
			<TextWithPagesElementPageForm
				textWithPagesElementPageFragmentRef={page}
				onSubmit={onSubmit}
				isCreating={!page}
			/>
		</Dialog>
	);
};
