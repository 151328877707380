import graphql from "babel-plugin-relay/macro";

export const USER_FRAGMENT = graphql`
	fragment editUserForm_UserFragment on User {
		activated
		email
		name
		id
		registeredAt
		isDeleted
		groupAssociations {
			account {
				name
			}
			group {
				id
				name
			}
		}
		extension(userExtensionKind: Academies) {
			... on AcademiesUserExtension {
				firstName
				lastName
				adsOptIn
				branch
				teamSize
				position
				avatar {
					id
					name
					url
				}
			}
		}
	}
`;

export const EDIT_USER_MUTATION = graphql`
	mutation editUserForm_EditMutation($input: EditUserInput!) {
		Admin {
			Auth {
				editUser(input: $input) {
					user {
						...editUserForm_UserFragment
					}
				}
			}
		}
	}
`;
