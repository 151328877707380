/**
 * @generated SignedSource<<9db55c429f9f9628d4e333718e3fde34>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteVimeoVideoSourceInput = {
  clientMutationId?: string | null;
  ids: ReadonlyArray<string>;
};
export type deleteVideoSourceButton_DeleteVimeoVideoSourceMutation$variables = {
  connections: ReadonlyArray<string>;
  input: DeleteVimeoVideoSourceInput;
};
export type deleteVideoSourceButton_DeleteVimeoVideoSourceMutation$data = {
  readonly Admin: {
    readonly VimeoVideoSource: {
      readonly deleteVimeoVideoSource: {
        readonly deletedIds: ReadonlyArray<string>;
      } | null;
    };
  };
};
export type deleteVideoSourceButton_DeleteVimeoVideoSourceMutation = {
  response: deleteVideoSourceButton_DeleteVimeoVideoSourceMutation$data;
  variables: deleteVideoSourceButton_DeleteVimeoVideoSourceMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedIds",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteVideoSourceButton_DeleteVimeoVideoSourceMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VimeoVideoSourceAdminMutationSchema",
            "kind": "LinkedField",
            "name": "VimeoVideoSource",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DeleteVimeoVideoSourcePayload",
                "kind": "LinkedField",
                "name": "deleteVimeoVideoSource",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "deleteVideoSourceButton_DeleteVimeoVideoSourceMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VimeoVideoSourceAdminMutationSchema",
            "kind": "LinkedField",
            "name": "VimeoVideoSource",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DeleteVimeoVideoSourcePayload",
                "kind": "LinkedField",
                "name": "deleteVimeoVideoSource",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "deleteEdge",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "deletedIds",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2c9d78fd5f0bba7eb7b56d02cc11ab00",
    "id": null,
    "metadata": {},
    "name": "deleteVideoSourceButton_DeleteVimeoVideoSourceMutation",
    "operationKind": "mutation",
    "text": "mutation deleteVideoSourceButton_DeleteVimeoVideoSourceMutation(\n  $input: DeleteVimeoVideoSourceInput!\n) {\n  Admin {\n    VimeoVideoSource {\n      deleteVimeoVideoSource(input: $input) {\n        deletedIds\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0363c6e288f9bab0f10b9b757e2e6af9";

export default node;
