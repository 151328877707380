import type { userRootNodeItemTree_TreeNodeInlineFragment$data } from "@relay/userRootNodeItemTree_TreeNodeInlineFragment.graphql";
import type { TreeItem } from "./user-root-node-item-tree.types";

export const buildTree = ({
	nodes,
	currentNode,
	firstContentCoordinatesIndexPath,
	currentHeadContentId,
}: {
	nodes: readonly userRootNodeItemTree_TreeNodeInlineFragment$data[];
	currentNode: userRootNodeItemTree_TreeNodeInlineFragment$data;
	firstContentCoordinatesIndexPath?: readonly number[];
	currentHeadContentId?: string;
}): TreeItem => {
	let seenCurrentHead = false;
	let seenPaywall = false;

	const buildTreeNode = (
		node: userRootNodeItemTree_TreeNodeInlineFragment$data,
		depth = 0,
		currentPath = [0],
	): TreeItem => {
		let children: TreeItem[] = [];
		const isCurrentHead = node.id === currentHeadContentId;
		if (isCurrentHead) {
			seenCurrentHead = true;
		}
		const isCurrentHeadOrAfterCurrentHead = isCurrentHead || seenCurrentHead;

		switch (node.typeDefinition.definitionType) {
			case "branch":
				children =
					node.typeDefinition.childRefs?.map((childId, index) => {
						return buildTreeNode(nodes.find((n) => childId === n.id)!, depth + 1, [
							...currentPath,
							index,
						]);
					}) ?? [];
				break;
			default:
				children = [];
				break;
		}

		const isPaywall =
			(firstContentCoordinatesIndexPath?.every((index, i) => index === currentPath[i]) &&
				currentPath.length === firstContentCoordinatesIndexPath.length) ??
			false;
		if (isPaywall) {
			seenPaywall = true;
		}

		const isAfterPaywall = isPaywall || seenPaywall;

		return {
			id: node.id,
			children,
			data: node,
			isCurrentHead,
			isPaywall,
			isAfterPaywall,
			isCurrentHeadOrAfterCurrentHead,
		};
	};

	return buildTreeNode(currentNode);
};

/**
 * Don't use this, this is not really ideal...
 **/
export const learnOpportunityV2IdToTreeNodeId = (learnOpportunityV2Id: string): string => {
	return btoa(atob(learnOpportunityV2Id).replace("LearnOpportunityV2:", "TreeNode:"));
};
