import * as Yup from "yup";
import { type FileV2 } from "@features/files/file-selection-field";
import { type Branch, type TeamSize } from "@relay/editUserForm_EditMutation.graphql";
import { type editUserForm_UserFragment$key } from "@relay/editUserForm_UserFragment.graphql";

export const EditUserFormValidationSchema = Yup.object().shape({
	firstName: Yup.string().required("Vorname wird benötigt"),
	lastName: Yup.string().required("Nachname wird benötigt"),
	email: Yup.string().email().required("Email wird benötigt"),
	activated: Yup.bool().required("Aktivierungsstatus wird benötigt"),
	branch: Yup.string().optional(),
	teamSize: Yup.string().optional(),
	position: Yup.string().optional(),
	adsOptIn: Yup.bool().optional(),
	imageId: Yup.string().optional(),
});

export type EditUserFormState = {
	email: string;
	firstName: string;
	lastName: string;
	activated: boolean;
	adsOptIn: boolean;
	branch?: Branch;
	teamSize?: TeamSize;
	position?: string;
	image?: FileV2;
};

export type EditUserFormProps = {
	userFragmentRef?: editUserForm_UserFragment$key | null;
};
