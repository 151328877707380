import { graphql } from "babel-plugin-relay/macro";

export const TEXT_WITH_PAGES_ELEMENT_FRAGMENT = graphql`
	fragment textWithPagesElementPagesEditor_PagesFragment on TextWithPagesElement {
		id
		pages {
			id
			internalTitle
			data {
				kind
			}
			...textWithPagesElementPageFormDialog_PageFragment
		}
	}
`;
