import { useLazyLoadQuery } from "react-relay";
import type { levelDefinition_Query } from "@relay/levelDefinition_Query.graphql";
import { BaseScreen } from "@components/base-screen";
import { H1Span } from "@themes/font-tags";
import { LevelDefinitionScreenContextProvider } from "./level-definition.context";
import { QUERY } from "./level-definition.graphql";
import { wrapperClass } from "./level-definition.styles";
import { LevelDefinitionTable } from "./parts/level-definition-table";

export const LevelDefinitionScreen = () => {
	const query = useLazyLoadQuery<levelDefinition_Query>(
		QUERY,
		{},
		{ fetchPolicy: "network-only" },
	);

	return (
		<LevelDefinitionScreenContextProvider>
			<BaseScreen>
				<div className={wrapperClass}>
					<H1Span>Level</H1Span>
					<LevelDefinitionTable queryFragmentRef={query} />
				</div>
			</BaseScreen>
		</LevelDefinitionScreenContextProvider>
	);
};
