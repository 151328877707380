/**
 * @generated SignedSource<<00c0ee7391a551b3b1e172ca3ccacb93>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type licensesTable_AccountFragment$data = {
  readonly extension: {
    readonly licenses?: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly " $fragmentSpreads": FragmentRefs<"licensesTable_LicenseFragment">;
        };
      } | null> | null;
    };
  } | null;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "licensesTable_AccountFragment";
};
export type licensesTable_AccountFragment$key = {
  readonly " $data"?: licensesTable_AccountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"licensesTable_AccountFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "licensesTable_AccountFragment"
};

(node as any).hash = "9c7e3d1a4a31de640f02b204429d899d";

export default node;
