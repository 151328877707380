import { graphql } from "babel-plugin-relay/macro";

export const ADD_TEXT_PAGE_MUTATION = graphql`
	mutation useTextWithPagesElementPageMutations_AddTextPageMutation(
		$input: AddTextPageToTextWithPagesElementInput!
	) {
		Admin {
			ElearningV2 {
				addTextPageToTextWithPagesElement(input: $input) {
					textWithPagesElement {
						...textWithPagesElementForm_TextWithPagesElementFragment
					}
				}
			}
		}
	}
`;
export const ADD_IMAGE_PAGE_MUTATION = graphql`
	mutation useTextWithPagesElementPageMutations_AddImagePageMutation(
		$input: AddImagePageToTextWithPagesElementInput!
	) {
		Admin {
			ElearningV2 {
				addImagePageToTextWithPagesElement(input: $input) {
					textWithPagesElement {
						...textWithPagesElementForm_TextWithPagesElementFragment
					}
				}
			}
		}
	}
`;

export const EDIT_TEXT_PAGE_MUTATION = graphql`
	mutation useTextWithPagesElementPageMutations_EditTextPageMutation(
		$input: EditTextPageFromTextWithPagesElementInput!
	) {
		Admin {
			ElearningV2 {
				editTextPageFromTextWithPagesElement(input: $input) {
					textWithPagesElement {
						...textWithPagesElementForm_TextWithPagesElementFragment
					}
				}
			}
		}
	}
`;

export const EDIT_IMAGE_PAGE_MUTATION = graphql`
	mutation useTextWithPagesElementPageMutations_EditImagePageMutation(
		$input: EditImagePageFromTextWithPagesElementInput!
	) {
		Admin {
			ElearningV2 {
				editImagePageFromTextWithPagesElement(input: $input) {
					textWithPagesElement {
						...textWithPagesElementForm_TextWithPagesElementFragment
					}
				}
			}
		}
	}
`;

export const DELETE_PAGE_MUTATION = graphql`
	mutation useTextWithPagesElementPageMutations_DeletePageMutation(
		$input: RemovePageFromTextWithPagesElementInput!
	) {
		Admin {
			ElearningV2 {
				removePageFromTextWithPagesElement(input: $input) {
					textWithPagesElement {
						...textWithPagesElementForm_TextWithPagesElementFragment
					}
				}
			}
		}
	}
`;
export const REARRANGE_PAGES_MUTATION = graphql`
	mutation useTextWithPagesElementPageMutations_RearrangePagesMutation(
		$input: RearrangePagesInTextWithPagesElementInput!
	) {
		Admin {
			ElearningV2 {
				rearrangePagesInTextWithPagesElement(input: $input) {
					textWithPagesElement {
						...textWithPagesElementForm_TextWithPagesElementFragment
					}
				}
			}
		}
	}
`;
