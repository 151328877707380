/**
 * @generated SignedSource<<39cc1fedbf51ec642232db8f3846548a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Persolog_ClassType = "D" | "G" | "I" | "S";
export type AddQuestionToPersologElementInput = {
  clientMutationId?: string | null;
  elementId: string;
  questionClasses: ReadonlyArray<Persolog_QuestionClassInput>;
  title: string;
};
export type Persolog_QuestionClassInput = {
  classType: Persolog_ClassType;
  text: string;
};
export type persologElementQuestionsEditor_AddQuestionToPersologElementMutation$variables = {
  input: AddQuestionToPersologElementInput;
};
export type persologElementQuestionsEditor_AddQuestionToPersologElementMutation$data = {
  readonly Admin: {
    readonly ElearningV2: {
      readonly addQuestionToPersologElement: {
        readonly persologElement: {
          readonly " $fragmentSpreads": FragmentRefs<"persologElementForm_PersologElementFragment">;
        };
      } | null;
    };
  };
};
export type persologElementQuestionsEditor_AddQuestionToPersologElementMutation = {
  response: persologElementQuestionsEditor_AddQuestionToPersologElementMutation$data;
  variables: persologElementQuestionsEditor_AddQuestionToPersologElementMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "classType",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "persologElementQuestionsEditor_AddQuestionToPersologElementMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AddQuestionToPersologElementPayload",
                "kind": "LinkedField",
                "name": "addQuestionToPersologElement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Persolog_PersologElement",
                    "kind": "LinkedField",
                    "name": "persologElement",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "persologElementForm_PersologElementFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "persologElementQuestionsEditor_AddQuestionToPersologElementMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AddQuestionToPersologElementPayload",
                "kind": "LinkedField",
                "name": "addQuestionToPersologElement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Persolog_PersologElement",
                    "kind": "LinkedField",
                    "name": "persologElement",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Persolog_Class",
                        "kind": "LinkedField",
                        "name": "classes",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "File",
                            "kind": "LinkedField",
                            "name": "image",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Persolog_Question",
                        "kind": "LinkedField",
                        "name": "questions",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Persolog_QuestionClass",
                            "kind": "LinkedField",
                            "name": "questionClasses",
                            "plural": true,
                            "selections": [
                              (v4/*: any*/),
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "963b6cb1b023347722c9ddd9ec66c324",
    "id": null,
    "metadata": {},
    "name": "persologElementQuestionsEditor_AddQuestionToPersologElementMutation",
    "operationKind": "mutation",
    "text": "mutation persologElementQuestionsEditor_AddQuestionToPersologElementMutation(\n  $input: AddQuestionToPersologElementInput!\n) {\n  Admin {\n    ElearningV2 {\n      addQuestionToPersologElement(input: $input) {\n        persologElement {\n          ...persologElementForm_PersologElementFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment persologClassForm_PersologClassFragment on Persolog_Class {\n  title\n  text\n  classType\n  image {\n    id\n    name\n  }\n}\n\nfragment persologElementForm_PersologElementFragment on Persolog_PersologElement {\n  id\n  title\n  classes {\n    title\n    text\n    classType\n    image {\n      id\n      name\n    }\n    ...persologClassForm_PersologClassFragment\n  }\n  questions {\n    id\n  }\n  ...persologElementQuestionsEditor_QuestionsFragment\n}\n\nfragment persologElementQuestionClassForm_PersologQuestionClassFragment on Persolog_QuestionClass {\n  text\n  classType\n}\n\nfragment persologElementQuestionFormDialog_PersologElementFragment on Persolog_PersologElement {\n  ...persologElementQuestionForm_PersologElementFragment\n}\n\nfragment persologElementQuestionFormDialog_QuestionFragment on Persolog_Question {\n  title\n  ...persologElementQuestionForm_QuestionFragment\n}\n\nfragment persologElementQuestionForm_PersologElementFragment on Persolog_PersologElement {\n  classes {\n    classType\n    title\n  }\n}\n\nfragment persologElementQuestionForm_QuestionFragment on Persolog_Question {\n  id\n  title\n  questionClasses {\n    text\n    classType\n    ...persologElementQuestionClassForm_PersologQuestionClassFragment\n  }\n}\n\nfragment persologElementQuestionsEditor_QuestionsFragment on Persolog_PersologElement {\n  id\n  ...persologElementQuestionFormDialog_PersologElementFragment\n  questions {\n    id\n    title\n    ...persologElementQuestionFormDialog_QuestionFragment\n  }\n}\n"
  }
};
})();

(node as any).hash = "7b705852a82d95c1bf92406c4c20e331";

export default node;
