import * as Yup from "yup";
import type { FileV2 } from "@features/files/file-selection-field";
import type { levelDefinitionForm_LevelDefinitionFragment$key } from "@relay/levelDefinitionForm_LevelDefinitionFragment.graphql";

export type LevelDefinitionFormProps = {
	levelDefinitionFragmentRef: levelDefinitionForm_LevelDefinitionFragment$key;
	onSubmit?: (values: LevelDefinitionFormState) => void;
};

export const LevelDefintionFormSchema = Yup.object().shape({
	title: Yup.string().required("Bitte gebe einen Titel ein"),
	shortDescription: Yup.string().required("Bitte gebe eine Kurzbeschreibung ein"),
	text: Yup.string().required("Bitte gebe einen Text ein"),
	image: Yup.object()
		.shape({
			id: Yup.string().optional(),
			name: Yup.string().optional(),
			url: Yup.string().optional(),
		})
		.optional()
		.nullable(),
});

export type LevelDefinitionFormState = Omit<
	Yup.InferType<typeof LevelDefintionFormSchema>,
	"image"
> & {
	image?: FileV2 | null;
};
