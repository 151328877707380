import { ButtonType, EpicIcons } from "@thekeytechnology/epic-ui";
import { useNavigate } from "react-router-dom";
import { Button } from "@components/button";
import {
	DefaultPriceFieldComponent,
	DefaultSwitchComponent,
	DefaultTextFieldComponent,
} from "@components/DefaultTextInput";
import { ValidatedField } from "@components/ValidatedField";
import {
	ProductFormProps,
	ProductFormState,
} from "@screens/product-edit/parts/product-form/product-form.types";

export const ProductForm = ({
	formik,
	handleSubmit,
	disabledFields,
	children,
}: ProductFormProps) => {
	const navigate = useNavigate();

	const handleBackOnClick = () => {
		navigate(-1);
	};
	return (
		<form onSubmit={handleSubmit} className="p-fluid">
			<div className="flex flex-column">
				<div className="flex flex-row justify-content-between mb-3">
					<Button type={ButtonType.Button} onClick={handleBackOnClick} label="Zurück" />
					<Button
						disabled={!formik.isValid}
						type={ButtonType.Submit}
						icon={EpicIcons.SAVE}
						label="Speichern"
					/>
				</div>
				<ValidatedField<ProductFormState, string>
					name={"title"}
					label="Name"
					required={true}
					disabled={disabledFields?.includes("title")}
					component={DefaultTextFieldComponent}
					formikConfig={formik}
				/>
				<ValidatedField<ProductFormState, boolean>
					name={"isHidden"}
					label="Versteckt"
					required={true}
					component={DefaultSwitchComponent}
					disabled={disabledFields?.includes("isHidden")}
					formikConfig={formik}
				/>
				<ValidatedField<ProductFormState, number>
					name={"netPrice"}
					label="Preis (Netto)"
					disabled={disabledFields?.includes("netPrice")}
					component={DefaultPriceFieldComponent}
					formikConfig={formik}
				/>
				<ValidatedField<ProductFormState, boolean>
					name={"isTaxFree"}
					label="Umsatzsteuerbefreit"
					required={true}
					component={DefaultSwitchComponent}
					disabled={disabledFields?.includes("isHidden")}
					formikConfig={formik}
				/>
				{children}
			</div>
		</form>
	);
};
