import graphql from "babel-plugin-relay/macro";

export const USER_QUERY = graphql`
	query userEditScreen_Query($id: ID!, $skip: Boolean!) {
		node(id: $id) @skip(if: $skip) {
			... on User {
				name
				...editUserForm_UserFragment
			}
		}

		...userRootNodes_QueryFragment @arguments(userId: $id)
		...ordersTable_OrdersFragment @arguments(userId: $id)
	}
`;
