import { Button, ButtonType, H2Span } from "@thekeytechnology/epic-ui";
import { useFormik } from "formik";
import { Divider } from "primereact/divider";
import React, { createRef, RefObject, useRef } from "react";
import { useFragment } from "react-relay";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { ValidatedField } from "@components/ValidatedField";
import { Persolog_ClassType } from "@relay/persologElementQuestionClassForm_PersologQuestionClassFragment.graphql";
import { persologElementQuestionForm_PersologElementFragment$key } from "@relay/persologElementQuestionForm_PersologElementFragment.graphql";
import { persologElementQuestionForm_QuestionFragment$key } from "@relay/persologElementQuestionForm_QuestionFragment.graphql";
import { PersologElementQuestionClassForm } from "@screens/educational-offer/parts/persolog-element-question-class-form/persolog-element-question-class-form.component";
import {
	PersologElementQuestionClassFormRef,
	PersologElementQuestionClassFormState,
} from "@screens/educational-offer/parts/persolog-element-question-class-form/persolog-element-question-class-form.types";
import { PersologElementQuestionFormSchema } from "@screens/educational-offer/parts/persolog-element-question-form/persolog-element-question-form.consts";
import {
	PERSOLOG_ELEMENT_FRAGMENT,
	QUESTION_FRAGMENT,
} from "@screens/educational-offer/parts/persolog-element-question-form/persolog-element-question-form.graphql";
import { formClass } from "@screens/educational-offer/parts/persolog-element-question-form/persolog-element-question-form.styles";
import {
	PersologElementQuestionFormProps,
	PersologElementQuestionFormState,
} from "@screens/educational-offer/parts/persolog-element-question-form/persolog-element-question-form.types";
import { stripHtml } from "../../../../util/html.utils";

export const PersologElementQuestionForm = ({
	persologElementFragmentRef,
	questionFragmentRef,
	onSubmit,
}: PersologElementQuestionFormProps) => {
	const persologElement = useFragment<persologElementQuestionForm_PersologElementFragment$key>(
		PERSOLOG_ELEMENT_FRAGMENT,
		persologElementFragmentRef,
	);

	const question = useFragment<persologElementQuestionForm_QuestionFragment$key>(
		QUESTION_FRAGMENT,
		questionFragmentRef ?? null,
	);

	const initialData: PersologElementQuestionFormState = {
		title: question?.title ?? "",
		questionClasses: question ? [...question?.questionClasses] : [],
	};

	const formik = useFormik<PersologElementQuestionFormState>({
		initialValues: initialData,
		validationSchema: PersologElementQuestionFormSchema,
		onSubmit: async (values) => {
			onSubmit?.(values);
		},
	});

	const findQuestionClass = (persologClassType: Persolog_ClassType) => {
		return question?.questionClasses.find(
			(questionClass) => questionClass.classType === persologClassType,
		);
	};

	const persologQuestionClassFormRefs = useRef<
		Array<RefObject<PersologElementQuestionClassFormRef>>
	>([]);
	persologQuestionClassFormRefs.current = persologElement.classes.map(
		(_, i) => persologQuestionClassFormRefs.current[i] || createRef(),
	);

	const handleOnSave = async () => {
		const updatedQuestionClasses: PersologElementQuestionClassFormState[] = [];

		const classFormsValidationResults = await Promise.all(
			persologQuestionClassFormRefs.current.map(async (ref) => {
				const isValid = await ref.current?.validate();
				await ref.current?.submit();
				if (isValid) {
					ref.current?.values ? updatedQuestionClasses.push(ref.current?.values) : "";
				}
				return isValid;
			}),
		);

		if (classFormsValidationResults.every((result) => result)) {
			await formik.setFieldValue("questionClasses", updatedQuestionClasses);
			await formik.submitForm();
		}
	};

	return (
		<div className={formClass}>
			<ValidatedField<PersologElementQuestionFormState, string>
				name={"title"}
				label={"Interner Titel"}
				required
				component={DefaultTextFieldComponent}
				formikConfig={formik}
			/>
			<Divider />
			{persologElement.classes.map((persologClass, index) => (
				<div key={stripHtml(persologClass.title)}>
					<H2Span>{stripHtml(persologClass.title)}</H2Span>
					<PersologElementQuestionClassForm
						ref={persologQuestionClassFormRefs.current[index]}
						persologElementQuestionClassFragmentRef={findQuestionClass(
							persologClass.classType,
						)}
						classType={persologClass.classType}
					/>
				</div>
			))}
			<Button type={ButtonType.Submit} label={"Speichern"} onClick={handleOnSave} />
		</div>
	);
};
