/**
 * @generated SignedSource<<224aef8dd42ad20d3c822af0fb375c1f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditVideoElementV2Input = {
  clientMutationId?: string | null;
  title: string;
  videoElementId: string;
  vimeoId: string;
  vimeoVideoSourceId: string;
};
export type videoElementForm_EditVideoElementMutation$variables = {
  input: EditVideoElementV2Input;
};
export type videoElementForm_EditVideoElementMutation$data = {
  readonly Admin: {
    readonly ElearningV2: {
      readonly editVideoElement: {
        readonly videoElement: {
          readonly " $fragmentSpreads": FragmentRefs<"videoElementForm_VideoElementFragment">;
        };
      } | null;
    };
  };
};
export type videoElementForm_EditVideoElementMutation = {
  response: videoElementForm_EditVideoElementMutation$data;
  variables: videoElementForm_EditVideoElementMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "videoElementForm_EditVideoElementMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditVideoElementV2Payload",
                "kind": "LinkedField",
                "name": "editVideoElement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VideoElement",
                    "kind": "LinkedField",
                    "name": "videoElement",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "videoElementForm_VideoElementFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "videoElementForm_EditVideoElementMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditVideoElementV2Payload",
                "kind": "LinkedField",
                "name": "editVideoElement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VideoElement",
                    "kind": "LinkedField",
                    "name": "videoElement",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "vimeoId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Vimeo_VimeoVideoSource",
                        "kind": "LinkedField",
                        "name": "vimeoVideoSource",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7b4a74b64fe5bd496d6c430773b78a5a",
    "id": null,
    "metadata": {},
    "name": "videoElementForm_EditVideoElementMutation",
    "operationKind": "mutation",
    "text": "mutation videoElementForm_EditVideoElementMutation(\n  $input: EditVideoElementV2Input!\n) {\n  Admin {\n    ElearningV2 {\n      editVideoElement(input: $input) {\n        videoElement {\n          ...videoElementForm_VideoElementFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment videoElementForm_VideoElementFragment on VideoElement {\n  id\n  title\n  vimeoId\n  vimeoVideoSource {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "0192caea167f5dea6d646b4ca8f0d242";

export default node;
