import { Card } from "primereact/card";
import { type DropdownChangeEvent } from "primereact/dropdown";
import { useFragment, useMutation } from "react-relay";
import {
	type AddCanAfterStartingTreeStartContentConfigInput,
	type startContentConfigEditor_addCanAfterStartingTreeStartContentConfigAdminMutation,
} from "@relay/startContentConfigEditor_addCanAfterStartingTreeStartContentConfigAdminMutation.graphql";
import {
	type AddCanIfUnlockedStartContentConfigInput,
	type startContentConfigEditor_addCanIfUnlockedStartContentConfigAdminMutation,
} from "@relay/startContentConfigEditor_addCanIfUnlockedStartContentConfigAdminMutation.graphql";
import { type startContentConfigEditor_RemoveStartContentConfigMutation } from "@relay/startContentConfigEditor_RemoveStartContentConfigMutation.graphql";
import { type StartContentConfigType } from "@relay/startContentConfigEditor_TreeNodeFragment.graphql";
import {
	ADD_CAN_AFTER_STARTING_TREE_START_CONTENT_CONFIG_MUTATION,
	ADD_CAN_IF_UNLOCKED_START_CONTENT_CONFIG_MUTATION,
	REMOVE_START_CONTENT_CONFIGURATION_MUTATION,
	TREE_NODE_FRAGMENT,
} from "./start-content-config-editor.graphql";
import { type StartContentConfigEditorProps } from "./start-content-config-editor.types";
import { type Config, type ConfigurationGraphqlInterface } from "../Config.interfaces";
import { ConfigDropdown, type ConfigDropdownOptions } from "../ConfigDropdown";
import { ConfigItem } from "../ConfigItem";
import { ConfigPreview } from "../ConfigPreview";

export const StartContentConfigEditor = ({
	treeNodeFragmentRef,
}: StartContentConfigEditorProps) => {
	const treeNode = useFragment(TREE_NODE_FRAGMENT, treeNodeFragmentRef);

	const [removeStartContentConfig, isRemovingStartContentConfig] =
		useMutation<startContentConfigEditor_RemoveStartContentConfigMutation>(
			REMOVE_START_CONTENT_CONFIGURATION_MUTATION,
		);

	const [addCanAfterStartingTreeConfig] =
		useMutation<startContentConfigEditor_addCanAfterStartingTreeStartContentConfigAdminMutation>(
			ADD_CAN_AFTER_STARTING_TREE_START_CONTENT_CONFIG_MUTATION,
		);
	const [addCanIfUnlockedStartContentConfig] =
		useMutation<startContentConfigEditor_addCanIfUnlockedStartContentConfigAdminMutation>(
			ADD_CAN_IF_UNLOCKED_START_CONTENT_CONFIG_MUTATION,
		);

	const configs: Array<Config<StartContentConfigType, any | never>> = [
		{
			// Is present by default and should not be removable
			configKey: "StartContent_CanIfInTreeFlow",
			addMutation: () => {},
			addMutationPayload: {},
			editable: false,
		},
		{
			configKey: "StartContent_CanIfUnlocked",
			addMutation: (input: AddCanIfUnlockedStartContentConfigInput) => {
				addCanIfUnlockedStartContentConfig({
					variables: {
						input,
					},
				});
			},
			addMutationPayload: {},
			editable: false,
		},
		{
			configKey: "StartContent_CanAfterStartingTree",
			addMutation: (input: AddCanAfterStartingTreeStartContentConfigInput) => {
				addCanAfterStartingTreeConfig({
					variables: {
						input,
					},
				});
			},
			addMutationPayload: {},
			editable: false,
		},
	];
	const options: Array<ConfigDropdownOptions<StartContentConfigType>> = configs.map((c) => {
		return {
			label: c.configKey,
			value: c.configKey,
		};
	});

	const dropDownOptions = options.filter((o) => o.value !== "StartContent_CanIfInTreeFlow");

	const handleOnChange = (e: DropdownChangeEvent) => {
		const selectedConfig = configs.find((c) => c.configKey === e.value);

		if (selectedConfig) {
			selectedConfig.addMutation({
				contentId: treeNode.id,
				...selectedConfig.addMutationPayload,
			});
		}
	};

	const handleOnDelete = (
		configuration: ConfigurationGraphqlInterface<StartContentConfigType>,
	) => {
		if (configuration.configType === "StartContent_CanIfInTreeFlow") return;
		removeStartContentConfig({
			variables: {
				input: {
					contentNodeId: treeNode.id,
					configId: configuration.id,
				},
			},
		});
	};

	return (
		<Card className="mb-2">
			<h2>Starten</h2>

			<ConfigDropdown<StartContentConfigType>
				configOptions={dropDownOptions}
				onChange={handleOnChange}
				isPresentational={false}
			/>

			<ConfigPreview<ConfigurationGraphqlInterface<StartContentConfigType>>
				selectedConfigs={treeNode.typeDefinition.startConfigs as any[]}
				template={(
					configuration: ConfigurationGraphqlInterface<StartContentConfigType>,
				) => (
					<>
						<ConfigItem<StartContentConfigType, string>
							isPresentational={true}
							isLoading={isRemovingStartContentConfig}
							configType={configuration.configType}
							canEdit={
								configs.find((c) => c.configKey === configuration.configType)
									?.editable as boolean
							}
							configOptions={options}
							onDelete={() => {
								handleOnDelete(configuration);
							}}
						/>
					</>
				)}
			/>
		</Card>
	);
};
