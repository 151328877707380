import { RelayPageDataTable } from "@thekeytechnology/epic-ui";
import { useContext } from "react";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { productsTable_ProductFragment$key } from "@relay/productsTable_ProductFragment.graphql";
import { productsTable_QueryFragment$key } from "@relay/productsTable_QueryFragment.graphql";
import { type productsTable_QueryFragmentRefetch } from "@relay/productsTable_QueryFragmentRefetch.graphql";
import { PRODUCTS_PER_PAGE } from "@screens/products/parts/products-table/products-table.const";
import { ProductsScreenContext } from "@screens/products/products.context";
import { QUERY_FRAGMENT, PRODUCT_FRAGMENT } from "./products-table.graphql";
import { type ProductsTableProps } from "./products-table.types";
import { ProductActionColumn } from "../ProductActionColumn";
import { ProductIdColumn } from "../ProductIdColumn";
import { ProductIsHiddenColumn } from "../ProductIsHiddenColumn";
import { ProductPriceColumn } from "../ProductPriceColumn";
import { ProductTitleColumn } from "../ProductTitleColumn";

export const ProductsTable = ({ productsQueryFragment }: ProductsTableProps) => {
	const { filters, connectionId, setConnectionId } = useContext(ProductsScreenContext);

	const handleOnConnectionIdChange = (id?: string | null) => {
		id && setConnectionId(id);
	};

	const canModify = useHasPermissions(["UserInAccountPermission_ProductAdmin_Modify"]);

	return (
		<>
			<RelayPageDataTable<
				productsTable_QueryFragmentRefetch,
				productsTable_QueryFragment$key,
				productsTable_ProductFragment$key
			>
				queryFragment={QUERY_FRAGMENT}
				parentFragmentRef={productsQueryFragment}
				nodeInlineFragment={PRODUCT_FRAGMENT}
				extractConnection={(d) => d.Admin.Billing.SearchProducts}
				rowsCount={PRODUCTS_PER_PAGE}
				filters={filters}
				onConnectionIdChange={handleOnConnectionIdChange}
			>
				{(Column) => (
					<>
						<Column
							header="ID"
							body={(item) => <ProductIdColumn productFragmentRef={item} />}
						/>
						<Column
							header="Titel"
							body={(item) => <ProductTitleColumn productFragmentRef={item} />}
						/>
						<Column
							header="Versteckt"
							body={(item) => <ProductIsHiddenColumn productFragmentRef={item} />}
						/>
						<Column
							header="Preis"
							body={(item) => <ProductPriceColumn productFragmentRef={item} />}
						/>
						{canModify && (
							<Column
								header="Aktionen"
								body={(item) => (
									<ProductActionColumn
										productFragmentRef={item}
										connectionId={connectionId}
									/>
								)}
							/>
						)}
					</>
				)}
			</RelayPageDataTable>
		</>
	);
};
