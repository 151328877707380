/**
 * @generated SignedSource<<d548189831301fd400ea206b1776bea1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type userRootNodeItem_RootNodesWithTreeStateTypeFragment$data = {
  readonly rootNode: {
    readonly " $fragmentSpreads": FragmentRefs<"userRootNodeItem_LearnOpportunityV2Fragment">;
  } | null;
  readonly treeState: {
    readonly " $fragmentSpreads": FragmentRefs<"userRootNodeItem_TreeStateFragment">;
  } | null;
  readonly " $fragmentType": "userRootNodeItem_RootNodesWithTreeStateTypeFragment";
};
export type userRootNodeItem_RootNodesWithTreeStateTypeFragment$key = {
  readonly " $data"?: userRootNodeItem_RootNodesWithTreeStateTypeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"userRootNodeItem_RootNodesWithTreeStateTypeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "userRootNodeItem_RootNodesWithTreeStateTypeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnOpportunityV2",
      "kind": "LinkedField",
      "name": "rootNode",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "userRootNodeItem_LearnOpportunityV2Fragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TreeState",
      "kind": "LinkedField",
      "name": "treeState",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "userRootNodeItem_TreeStateFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RootNodesWithTreeStateType",
  "abstractKey": null
};

(node as any).hash = "c33ffd6517566ec833aab64aec6305c1";

export default node;
