import graphql from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment productsTable_QueryFragment on Query
	@refetchable(queryName: "productsTable_QueryFragmentRefetch")
	@argumentDefinitions(
		first: { type: "Int" }
		last: { type: "Int" }
		after: { type: "String", defaultValue: null }
		before: { type: "String", defaultValue: null }
		isHiddenOpt: { type: "Boolean" }
		titleOpt: { type: "String" }
		inKinds: { type: "[ProductKind!]", defaultValue: [License] }
	) {
		Admin {
			Billing {
				SearchProducts(
					isHiddenOpt: $isHiddenOpt
					titleOpt: $titleOpt
					after: $after
					before: $before
					first: $first
					last: $last
					inKinds: $inKinds
				) @connection(key: "productsTable_SearchProducts") {
					__id
					pageInfo {
						endCursor
						hasPreviousPage
						hasNextPage
						startCursor
					}
					edges {
						cursor
						node {
							...productsTable_ProductFragment
						}
					}
				}
			}
		}
	}
`;

export const PRODUCT_FRAGMENT = graphql`
	fragment productsTable_ProductFragment on Product @inline {
		...ProductIdColumn_ProductFragment
		...ProductTitleColumn_ProductFragment
		...ProductIsHiddenColumn_ProductFragment
		...ProductPriceColumn_ProductFragment
		...ProductActionColumn_ProductFragment
	}
`;
