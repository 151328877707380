import { Card } from "@components/card";
import { cx } from "@styled-system/css";
import { contentContainerCardClass, wrapperClass } from "./base-screen.styles";
import type { BaseScreenProps } from "./base-screen.types";

export const BaseScreen = ({ children, className, ...props }: BaseScreenProps) => {
	return (
		<div className={wrapperClass}>
			<Card className={cx(contentContainerCardClass, className)} {...props}>
				{children}
			</Card>
		</div>
	);
};
