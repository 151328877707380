import { BaseScreen } from "@components/base-screen";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { NoAccess } from "../../components/no-access";
import { FilesTable } from "../../features/files/files-table";

export const FilesScreen = () => {
	const canRead = useHasPermissions("onlyOwnerOfRoot");

	if (!canRead) return <NoAccess />;

	return (
		<BaseScreen>
			<h1>Dateien</h1>

			<FilesTable canUploadFiles={true} canDeleteFiles={true} />
		</BaseScreen>
	);
};
