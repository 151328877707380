/**
 * @generated SignedSource<<f3e8f7d013d1902a023b07021e0eabdf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type levelDefinitionForm_LevelDefinitionFragment$data = {
  readonly id: string;
  readonly image: {
    readonly id: string;
    readonly name: string;
    readonly url: string | null;
  } | null;
  readonly requiredPoints: number;
  readonly shortDescription: string;
  readonly text: string;
  readonly title: string;
  readonly " $fragmentType": "levelDefinitionForm_LevelDefinitionFragment";
};
export type levelDefinitionForm_LevelDefinitionFragment$key = {
  readonly " $data"?: levelDefinitionForm_LevelDefinitionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"levelDefinitionForm_LevelDefinitionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "levelDefinitionForm_LevelDefinitionFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requiredPoints",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LevelDefinition",
  "abstractKey": null
};
})();

(node as any).hash = "6fcdc5d93a8fc659ec27644aded4459b";

export default node;
