import { AcademiesTagsRoutes } from "@screens/academies-tags";
import { AccountsRoutes } from "@screens/accounts";
import { DiscountActionsRoutes } from "@screens/discount-actions";
import { EducationalOfferRoutes } from "@screens/educational-offer";
import { EmailTemplatesRoutes } from "@screens/email-templates";
import { ExternalLicensesRoutes } from "@screens/external-license-edit";
import { ExternalLicensePoolsRoutes } from "@screens/external-license-pools";
import { FilesRoutes } from "@screens/files/files.routes";
import { GenerateCartLinkRoutes } from "@screens/generate-cart-link";
import { InstructorsRoutes } from "@screens/instructors";
import { LevelDefinitionRoutes } from "@screens/level-definition";
import { LicenseDefinitionsRoutes } from "@screens/license-definitions";
import { LicensesRoutes } from "@screens/licenses";
import { LimitedCartDiscountsRoutes } from "@screens/limited-cart-discounts";
import { MessageDefinitionsRoutes } from "@screens/message-definitions";
import { OffersRoutes } from "@screens/offers";
import { OrdersRoutes } from "@screens/orders";
import { ProductsRoutes } from "@screens/products";
import { ReportsRoutes } from "@screens/reports/reports.routes";
import { SettingsRoutes } from "@screens/settings";
import { StagingRoutes } from "@screens/staging";
import { TasksRoutes } from "@screens/tasks";
import { UsersRoutes } from "@screens/users";
import { VideoSourcesRoutes } from "@screens/video-sources/video-sources.routes";
import { MessagesRoutes } from "src/screens/message-dispatches";

export const AppRoutes = [
	...OffersRoutes,
	...EducationalOfferRoutes,
	...FilesRoutes,
	...UsersRoutes,
	...TasksRoutes,
	...StagingRoutes,
	...SettingsRoutes,
	...LicensesRoutes,
	...InstructorsRoutes,
	...LevelDefinitionRoutes,
	...EmailTemplatesRoutes,
	...AccountsRoutes,
	...AcademiesTagsRoutes,
	...OrdersRoutes,
	...ProductsRoutes,
	...LimitedCartDiscountsRoutes,
	...GenerateCartLinkRoutes,
	...DiscountActionsRoutes,
	...LicenseDefinitionsRoutes,
	...ExternalLicensePoolsRoutes,
	...ExternalLicensesRoutes,
	...ReportsRoutes,
	...MessagesRoutes,
	...MessageDefinitionsRoutes,
	...VideoSourcesRoutes,
];
