import { RelayPageDataTable } from "@thekeytechnology/epic-ui";
import { useCallback, useContext } from "react";
import { useRefetchableFragment } from "react-relay";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { videoSourcesTable_QueryFragment$key } from "@relay/videoSourcesTable_QueryFragment.graphql";
import { videoSourcesTable_QueryRefetch } from "@relay/videoSourcesTable_QueryRefetch.graphql";
import { videoSourcesTable_Vimeo_VimeoVideoSourceFragment$key } from "@relay/videoSourcesTable_Vimeo_VimeoVideoSourceFragment.graphql";
import { VideoSourcesScreenContext } from "@screens/video-sources/video-sources.context";
import { VIDEO_SOURCES_PER_PAGE } from "./video-sources-table.const";
import { QUERY_FRAGMENT, VIDEO_SOURCE_VIDEO_SOURCE_FRAGMENT } from "./video-sources-table.graphql";
import { VideoSourcesTableProps } from "./video-sources-table.types";
import { VideoSourcesTableActionColumn } from "../video-sources-table-action-column";

export const VideoSourcesTable = ({ queryFragmentRef }: VideoSourcesTableProps) => {
	const { setConnectionId } = useContext(VideoSourcesScreenContext);

	const handleOnConnectionIdChange = (id?: string | null) => {
		id && setConnectionId(id);
	};
	const [_, refetch] = useRefetchableFragment<
		videoSourcesTable_QueryRefetch,
		videoSourcesTable_QueryFragment$key
	>(QUERY_FRAGMENT, queryFragmentRef);

	const refresh = useCallback(() => {
		refetch({}, { fetchPolicy: "network-only" });
	}, [refetch]);

	const canModify = useHasPermissions(["UserInAccountPermission_VimeoVideoSourceAdmin_Modify"]);

	return (
		<RelayPageDataTable<
			videoSourcesTable_QueryRefetch,
			videoSourcesTable_QueryFragment$key,
			videoSourcesTable_Vimeo_VimeoVideoSourceFragment$key
		>
			queryFragment={QUERY_FRAGMENT}
			parentFragmentRef={queryFragmentRef}
			nodeInlineFragment={VIDEO_SOURCE_VIDEO_SOURCE_FRAGMENT}
			extractConnection={(data) => data.Admin.VimeoVideoSource?.VimeoVideoSources}
			rowsCount={VIDEO_SOURCES_PER_PAGE}
			onConnectionIdChange={handleOnConnectionIdChange}
		>
			{(Column) => (
				<>
					<Column header={"Name"} body={(videoSource) => videoSource?.name} />
					<Column
						header={"Access-Token"}
						body={(videoSource) => videoSource?.accessToken}
					/>
					<Column
						header={"Ist Standard-Video-Quelle"}
						body={(videoSource) => (videoSource?.isDefault ? "Ja" : "")}
					/>
					{canModify && (
						<Column
							header="Aktionen"
							body={(videoSource) => (
								<VideoSourcesTableActionColumn
									onRefresh={refresh}
									videoSourceFragmentRef={videoSource}
								/>
							)}
						/>
					)}
				</>
			)}
		</RelayPageDataTable>
	);
};
