import graphql from "babel-plugin-relay/macro";

export const TRIGGER_RESET_PASSWORD_MUTATION = graphql`
	mutation resetPasswordButton_TriggerResetPasswordMutation($input: TriggerResetPasswordInput!) {
		Admin {
			Auth {
				triggerResetPassword(input: $input) {
					__typename
				}
			}
		}
	}
`;
