/**
 * @generated SignedSource<<4c5638841fc86651d76f89a78b446222>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type levelDefinitionTable_LevelDefinitionInlineFragment$data = {
  readonly id: string;
  readonly requiredPoints: number;
  readonly shortDescription: string;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"editLevelDefinitionButton_LevelDefinitionFragment">;
  readonly " $fragmentType": "levelDefinitionTable_LevelDefinitionInlineFragment";
};
export type levelDefinitionTable_LevelDefinitionInlineFragment$key = {
  readonly " $data"?: levelDefinitionTable_LevelDefinitionInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"levelDefinitionTable_LevelDefinitionInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "levelDefinitionTable_LevelDefinitionInlineFragment"
};

(node as any).hash = "ba7077afd0f52dc5e101613f686dedd1";

export default node;
