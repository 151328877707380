import { RelayPageDataTable } from "@thekeytechnology/epic-ui";
import { useContext } from "react";
import { offersTable_QueryFragment$key } from "@relay/offersTable_QueryFragment.graphql";
import { type offersTable_Refetch } from "@relay/offersTable_Refetch.graphql";
import { type offersTable_UnpublishedLearnableFragment$key } from "@relay/offersTable_UnpublishedLearnableFragment.graphql";
import { DEFAULT_ROWS_COUNT, learnableKindTranslations } from "./offers-table.consts";
import { UNPUBLISHED_LEARNABLE_FRAGMENT, QUERY_FRAGMENT } from "./offers-table.graphql";
import { wrapperClass } from "./offers-table.styles";
import { type OffersTableProps } from "./offers-table.types";
import { OffersScreenContext } from "../../offers.context";
import { DeleteCourseLearnableButtonComponent } from "../delete-course-learnable-button";
import { EditCourseLearnableButton } from "../edit-course-learnable-button";

export const OffersTable = ({ queryFragmentRef }: OffersTableProps) => {
	const { connectionId, setConnectionId, filters } = useContext(OffersScreenContext);

	const handleOnConnectionIdChange = (id?: string | null) => {
		id && setConnectionId(id);
	};

	return (
		<RelayPageDataTable<
			offersTable_Refetch,
			offersTable_QueryFragment$key,
			offersTable_UnpublishedLearnableFragment$key
		>
			queryFragment={QUERY_FRAGMENT}
			parentFragmentRef={queryFragmentRef}
			nodeInlineFragment={UNPUBLISHED_LEARNABLE_FRAGMENT}
			extractConnection={(data) => data.Admin.Learnable.UnpublishedLearnables}
			filters={filters}
			rowsCount={DEFAULT_ROWS_COUNT}
			onConnectionIdChange={handleOnConnectionIdChange}
		>
			{(Column) => (
				<>
					<Column
						header="Name"
						body={(learnable) => learnable.root?.structureDefinition.title ?? ""}
					/>
					<Column
						header={"Typ"}
						body={(learnable) => learnableKindTranslations[learnable.kind] || ""}
					/>

					<Column
						header="Aktionen"
						style={{ width: "15%" }}
						body={(item) => (
							<div className={wrapperClass}>
								<EditCourseLearnableButton learnableFragmentRef={item} />
								<DeleteCourseLearnableButtonComponent
									connectionId={connectionId}
									learnableFragmentRef={item}
								/>
							</div>
						)}
					/>
				</>
			)}
		</RelayPageDataTable>
	);
};
