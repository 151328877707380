import { TextWithPagesPageKindDropdownType } from "@components/text-with-pages-element-page-kind-dropdown/text-with-pages-element-page-kind-dropdown.types";

export const textWithPagesPageKindOptions: TextWithPagesPageKindDropdownType[] = [
	{
		value: "ImagePageData",
		label: "Bild",
	},
	{
		value: "TextPageData",
		label: "Text",
	},
];
