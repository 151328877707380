/**
 * @generated SignedSource<<f9689550ed09920ab2fbf331466595e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteProductInput = {
  clientMutationId?: string | null;
  productId: string;
};
export type DeleteProductButton_DeleteMutation$variables = {
  connections: ReadonlyArray<string>;
  input: DeleteProductInput;
};
export type DeleteProductButton_DeleteMutation$data = {
  readonly Admin: {
    readonly Billing: {
      readonly deleteProduct: {
        readonly productId: string;
      } | null;
    };
  };
};
export type DeleteProductButton_DeleteMutation = {
  response: DeleteProductButton_DeleteMutation$data;
  variables: DeleteProductButton_DeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "productId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteProductButton_DeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DeleteProductPayload",
                "kind": "LinkedField",
                "name": "deleteProduct",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "DeleteProductButton_DeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DeleteProductPayload",
                "kind": "LinkedField",
                "name": "deleteProduct",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "deleteEdge",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "productId",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "757b2c6a53c57a62144ca7a7245ebe79",
    "id": null,
    "metadata": {},
    "name": "DeleteProductButton_DeleteMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteProductButton_DeleteMutation(\n  $input: DeleteProductInput!\n) {\n  Admin {\n    Billing {\n      deleteProduct(input: $input) {\n        productId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8f5330b72bc87a826eba93f17c99a557";

export default node;
