import { Button, EpicIcons } from "@thekeytechnology/epic-ui";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import type { editLevelDefinitionButton_LevelDefinitionFragment$key } from "@relay/editLevelDefinitionButton_LevelDefinitionFragment.graphql";
import { Paths } from "@routes/paths";
import { LEVEL_DEFINITION_FRAGMENT } from "./edit-level-definition-button.graphql";
import { EditLevelDefinitionButtonProps } from "./edit-level-definition-button.types";

export const EditLevelDefinitionButton = ({
	levelDefinitionFragmentRef,
}: EditLevelDefinitionButtonProps) => {
	const levelDefinition = useFragment<editLevelDefinitionButton_LevelDefinitionFragment$key>(
		LEVEL_DEFINITION_FRAGMENT,
		levelDefinitionFragmentRef,
	);

	const navigate = useNavigate();

	const handleOnClick = () => {
		navigate(Paths.levelDefinition.withId(levelDefinition.id).edit.path);
	};

	return (
		<Button
			icon={EpicIcons.PENCIL}
			tooltip={"Level-Definition anpassen"}
			onClick={handleOnClick}
		/>
	);
};
