import { createContext, useContext, useState } from "react";

export type UserRootNodesFilterContextType = {
	filter?: string;
	setFilter: (filter?: string) => void;
};

export const UserRootNodesFilterContext = createContext<UserRootNodesFilterContextType>({
	filter: "",
	setFilter: () => {},
});

export const UserRootNodesFilterContextProvider = ({ children }: React.PropsWithChildren) => {
	const [filter, setFilter] = useState<string>();

	return (
		<UserRootNodesFilterContext.Provider value={{ filter, setFilter }}>
			{children}
		</UserRootNodesFilterContext.Provider>
	);
};

export const useUserRootNodesFilter = () => {
	const context = useContext(UserRootNodesFilterContext);
	if (!context) {
		throw new Error(
			"useUserRootNodesFilter must be used within a UserRootNodesFilterContextProvider",
		);
	}

	return context;
};
