import { createContext, useState } from "react";

export type ILevelDefinitionContext = {
	connectionId: string;
	setConnectionId: React.Dispatch<string>;
};

export const LevelDefinitionScreenContext = createContext<ILevelDefinitionContext>({
	connectionId: "",
	setConnectionId: () => {},
});

export const LevelDefinitionScreenContextProvider = ({
	children,
}: React.PropsWithChildren<unknown>) => {
	const [connectionId, setConnectionId] = useState("");

	return (
		<LevelDefinitionScreenContext.Provider value={{ connectionId, setConnectionId }}>
			{children}
		</LevelDefinitionScreenContext.Provider>
	);
};
