import { grid } from "@styled-system/patterns";

export const twoColumnFieldWrapperClass = grid({
	gridTemplateColumns: "2",
	gap: "16",
	width: "full",
});

export const fourColumnFieldWrapperClass = grid({
	gridTemplateColumns: "3",
	width: "full",
});
