import { graphql } from "babel-plugin-relay/macro";

export const ELEMENTV2_FRAGMENT = graphql`
	fragment elementForm_ElementV2Fragment on ElementV2 {
		title
		elementType
		... on FileElementV2 {
			...fileElementForm_FileElementV2Fragment
		}
		... on VideoElement {
			...videoElementForm_VideoElementFragment
		}
		... on MultipleChoiceElementV2 {
			...multipleChoiceElementForm_MultipleChoiceElementV2Fragment
		}
		... on MarkMistakesElement {
			...MarkMistakeElementForm_MarkMistakeElementFragment
		}

		... on PodcastWithTimestamp {
			...PodcastWithTimestampElementForm_PodcastWithTimestampElementFragment
		}

		... on MatrixElement {
			...matrixElementForm_MatrixElementFragment
		}

		... on EnhancedTextElement {
			...enhancedTextElementForm_EnhancedTextElementFragment
		}

		... on OrderElement {
			...OrderElementForm_OrderElementFragment
		}
		... on ClozeTextElementV2 {
			...ClozeTextElementForm_ClozeTextElementFragment
		}
		... on TextElementV2 {
			...textElementForm_TextElementFragment
		}
		... on TextWithPagesElement {
			...textWithPagesElementForm_TextWithPagesElementFragment
		}
		... on Persolog_PersologElement {
			...persologElementForm_PersologElementFragment
		}
	}
`;
