/**
 * @generated SignedSource<<e69ced9d99abfb28fd374e01dcc25940>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TriggerResetPasswordInput = {
  clientMutationId?: string | null;
  userId: string;
};
export type resetPasswordButton_TriggerResetPasswordMutation$variables = {
  input: TriggerResetPasswordInput;
};
export type resetPasswordButton_TriggerResetPasswordMutation$data = {
  readonly Admin: {
    readonly Auth: {
      readonly triggerResetPassword: {
        readonly __typename: "TriggerResetPasswordPayload";
      } | null;
    };
  };
};
export type resetPasswordButton_TriggerResetPasswordMutation = {
  response: resetPasswordButton_TriggerResetPasswordMutation$data;
  variables: resetPasswordButton_TriggerResetPasswordMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminMutationType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthAdminMutationSchema",
        "kind": "LinkedField",
        "name": "Auth",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "input"
              }
            ],
            "concreteType": "TriggerResetPasswordPayload",
            "kind": "LinkedField",
            "name": "triggerResetPassword",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "resetPasswordButton_TriggerResetPasswordMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "resetPasswordButton_TriggerResetPasswordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c9a81da9e17567ac7daad1bc495ec25c",
    "id": null,
    "metadata": {},
    "name": "resetPasswordButton_TriggerResetPasswordMutation",
    "operationKind": "mutation",
    "text": "mutation resetPasswordButton_TriggerResetPasswordMutation(\n  $input: TriggerResetPasswordInput!\n) {\n  Admin {\n    Auth {\n      triggerResetPassword(input: $input) {\n        __typename\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "04ae3a511215e2b7ccc4638be73166ad";

export default node;
