import * as Yup from "yup";
import { Persolog_ClassType } from "@relay/persologClassForm_PersologClassFragment.graphql";

export const PersologClassFormSchema = Yup.object().shape({
	title: Yup.string().required("Titel ist erforderlich"),
	text: Yup.string().required("Text ist erforderlich"),
	image: Yup.object({
		id: Yup.string().optional(),
		name: Yup.string().optional(),
	}).optional(),
	classType: Yup.mixed<Persolog_ClassType>()
		.oneOf(["D", "G", "I", "S"], "Ungültiger Klassentyp")
		.required("Typ ist erforderlich"),
});
