import * as Yup from "yup";
import { PersologElementQuestionClassFormSchema } from "@screens/educational-offer/parts/persolog-element-question-class-form/persolog-element-question-class-form.consts";

export const PersologElementQuestionFormSchema = Yup.object({
	title: Yup.string().required("Bitte gib eine Frage ein."),
	questionClasses: Yup.array()
		.of(PersologElementQuestionClassFormSchema)
		.min(4)
		.required("Klassen sind erforderlich."),
});
