/**
 * @generated SignedSource<<03026962deccf495b9d3b92dfd11eae7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditNodeCoreInput = {
  clientMutationId?: string | null;
  description?: string | null;
  imageId?: string | null;
  instructorIds: ReadonlyArray<string>;
  nodeId: string;
  shortDescription?: string | null;
  title: string;
};
export type editNodeForm_EditNodeCoreMutation$variables = {
  input: EditNodeCoreInput;
};
export type editNodeForm_EditNodeCoreMutation$data = {
  readonly Admin: {
    readonly Tree: {
      readonly editNodeCore: {
        readonly editedNode: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"Node_TreeNodeFragment" | "editNodeForm_TreeNodeFragment">;
        };
      } | null;
    };
  };
};
export type editNodeForm_EditNodeCoreMutation = {
  response: editNodeForm_EditNodeCoreMutation$data;
  variables: editNodeForm_EditNodeCoreMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "definitionType",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editNodeForm_EditNodeCoreMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditNodeCorePayload",
                "kind": "LinkedField",
                "name": "editNodeCore",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "editedNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "editNodeForm_TreeNodeFragment"
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "Node_TreeNodeFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editNodeForm_EditNodeCoreMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditNodeCorePayload",
                "kind": "LinkedField",
                "name": "editNodeCore",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "editedNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "structureDefinition",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "extension",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "diploma",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "tags",
                                        "plural": true,
                                        "selections": [
                                          (v3/*: any*/),
                                          (v2/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "learnGoalsOpt",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Product",
                                        "kind": "LinkedField",
                                        "name": "product",
                                        "plural": false,
                                        "selections": [
                                          (v2/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "trailerOpt",
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "RootExtensionImpl",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "RootStructureDefinition",
                            "abstractKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "shortDescription",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "instructors",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "superId",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "imageId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "File",
                        "kind": "LinkedField",
                        "name": "image",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "typeDefinition",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "contentKind",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "rewards",
                                "plural": true,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "kind",
                                    "storageKey": null
                                  },
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "ContentTypeDefinition",
                            "abstractKey": "__isContentTypeDefinition"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "childRefs",
                                "storageKey": null
                              }
                            ],
                            "type": "BranchTypeDefinition",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Versioning",
                        "kind": "LinkedField",
                        "name": "versioning",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "draftVersion",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "releaseVersion",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7d94467d6d66a62e2f13de80d89915a6",
    "id": null,
    "metadata": {},
    "name": "editNodeForm_EditNodeCoreMutation",
    "operationKind": "mutation",
    "text": "mutation editNodeForm_EditNodeCoreMutation(\n  $input: EditNodeCoreInput!\n) {\n  Admin {\n    Tree {\n      editNodeCore(input: $input) {\n        editedNode {\n          id\n          ...editNodeForm_TreeNodeFragment\n          ...Node_TreeNodeFragment\n        }\n      }\n    }\n  }\n}\n\nfragment NodeConfigurationBadges_TreeNodeFragment on TreeNode {\n  id\n  structureDefinition {\n    __typename\n    definitionType\n  }\n  typeDefinition {\n    __typename\n    definitionType\n    ... on ContentTypeDefinition {\n      __isContentTypeDefinition: __typename\n      rewards {\n        __typename\n        kind\n        id\n      }\n    }\n  }\n  versioning {\n    draftVersion\n    releaseVersion\n  }\n}\n\nfragment Node_TreeNodeFragment on TreeNode {\n  id\n  structureDefinition {\n    __typename\n    title\n  }\n  typeDefinition {\n    __typename\n    definitionType\n    ... on ContentTypeDefinition {\n      __isContentTypeDefinition: __typename\n      contentKind\n    }\n    ... on BranchTypeDefinition {\n      childRefs\n    }\n  }\n  ...NodeConfigurationBadges_TreeNodeFragment\n}\n\nfragment editNodeForm_TreeNodeFragment on TreeNode {\n  id\n  structureDefinition {\n    __typename\n    title\n    ... on RootStructureDefinition {\n      extension {\n        __typename\n        ... on RootExtensionImpl {\n          diploma\n          tags {\n            __typename\n            id\n          }\n          learnGoalsOpt\n          product {\n            id\n          }\n          trailerOpt\n        }\n      }\n    }\n  }\n  description\n  shortDescription\n  instructors {\n    __typename\n    superId\n    id\n  }\n  imageId\n  image {\n    id\n    name\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "044610dcc62495484a912860068db11d";

export default node;
