import * as Yup from "yup";
import { PersologClassFormSchema } from "@screens/educational-offer/parts/persolog-class-form/persolog-class-form.consts";
import { PersologClassFormState } from "@screens/educational-offer/parts/persolog-class-form/persolog-class-form.types";

export const NUMBER_OF_PERSOLOG_QUESTIONS_REQUIRED = 10;

export const PersologElementFormSchema = Yup.object().shape({
	title: Yup.string().required("Das Feld Titel wird benötigt."),
	classes: Yup.array().of(PersologClassFormSchema).required("Klassen sind erforderlich."),
});

export const persologClasses: readonly PersologClassFormState[] = [
	{
		title: "Dominant",
		text: "",
		image: undefined,
		classType: "D",
	},
	{
		title: "Initiative",
		text: "",
		image: undefined,
		classType: "I",
	},
	{
		title: "Stetigkeit",
		text: "",
		image: undefined,
		classType: "S",
	},
	{
		title: "Gewissenhaft",
		text: "",
		image: undefined,
		classType: "G",
	},
] as const;
