import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { useUserRootNodesFilter } from "@screens/user-edit/parts/user-root-nodes-filter/user-root-nodes-filter.context";

export const UserRootNodesFilter = () => {
	const { filter, setFilter } = useUserRootNodesFilter();

	return (
		<DefaultTextFieldComponent
			fieldName="name"
			isValid={true}
			required={false}
			disabled={false}
			placeholder="Nach Namen filtern"
			fieldValue={filter}
			updateField={setFilter}
		/>
	);
};
