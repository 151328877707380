import { css } from "@styled-system/css";

export const wrapperClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "16",
});

export const formsWrapperClass = css({
	display: "grid",
	gridTemplateColumns: "1",
	alignItems: "start",
	gap: "16",
	width: "full",
	lg: {
		gridTemplateColumns: "[30vw minmax(0,1fr)]",
	},
});

export const userActionsWrapperClass = css({
	display: "flex",
	gap: "16",
	flexWrap: "wrap",
});

export const formsWrapperRightClass = css({
	display: "grid",
	gridTemplateRows: "[auto min-content]",
	gap: "16",
	width: "full",
	height: "full",
});

export const userRootNodesHeaderWrapperClass = css({
	display: "flex",
	gap: "16",
	alignItems: "center",
	paddingLeft: "16",
	paddingTop: "16",
});

export const userRootNodesContentWrapperClass = css({
	height: "[calc(100% - 2rem)]",
});
