import { ButtonType, EpicIcons } from "@thekeytechnology/epic-ui";
import { useFormik } from "formik";
import { Button, ButtonVariant } from "@components/button";
import {
	buttonWrapperClass,
	fieldWrapperClass,
	inputFieldClass,
} from "./users-table-filter.styles";
import { type UsersTableFilterProps, type UsersTableFilterState } from "./users-table-filter.types";
import { DefaultTextFieldComponent } from "../../../components/DefaultTextInput";
import { ValidatedField } from "../../../components/ValidatedField";

export const UsersTableFilter = ({ onChange }: UsersTableFilterProps) => {
	const initialValues = {};

	const formik = useFormik<UsersTableFilterState>({
		initialValues,
		onSubmit: (values: UsersTableFilterState, { setSubmitting }) => {
			onChange(values.name);
			setSubmitting(false);
		},
	});

	const clearForm = () => {
		formik.handleReset(initialValues);
		formik.handleSubmit();
	};

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<div className={fieldWrapperClass}>
				<ValidatedField<UsersTableFilterState, string>
					name="name"
					label="Benutzername oder E-Mail"
					className={inputFieldClass}
					component={DefaultTextFieldComponent}
					formikConfig={formik}
				/>
				<div className={buttonWrapperClass}>
					<Button
						type={ButtonType.Reset}
						icon={EpicIcons.TIMES}
						tooltip="Filter zurücksetzen"
						variant={ButtonVariant.Outline}
						onClick={clearForm}
					/>
					<Button
						type={ButtonType.Submit}
						icon={EpicIcons.SEARCH}
						tooltip="Filter anwenden"
					/>
				</div>
			</div>
		</form>
	);
};
