import { css } from "@styled-system/css";
import { stack } from "@styled-system/patterns";

export const wrapperClass = css({
	paddingX: "8",
});

export const treeWrapperClass = stack({
	gap: "[6px]",
});
