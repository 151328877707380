/**
 * @generated SignedSource<<ffc93d7d324250d3568cba20fb612086>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type selectProductField_Query$variables = {};
export type selectProductField_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"selectProductField_QueryFragment">;
};
export type selectProductField_Query = {
  response: selectProductField_Query$data;
  variables: selectProductField_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  },
  {
    "kind": "Literal",
    "name": "inKinds",
    "value": [
      "License"
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "selectProductField_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "selectProductField_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "selectProductField_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v0/*: any*/),
                "concreteType": "ProductsConnection",
                "kind": "LinkedField",
                "name": "SearchProducts",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Product",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": "SearchProducts(first:20,inKinds:[\"License\"])"
              },
              {
                "alias": null,
                "args": (v0/*: any*/),
                "filters": [
                  "titleOpt",
                  "isHiddenOpt",
                  "inKinds"
                ],
                "handle": "connection",
                "key": "SelectProductField_SearchProducts",
                "kind": "LinkedHandle",
                "name": "SearchProducts"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bd05947cf5627a983f57929ca4cbecad",
    "id": null,
    "metadata": {},
    "name": "selectProductField_Query",
    "operationKind": "query",
    "text": "query selectProductField_Query {\n  ...selectProductField_QueryFragment\n}\n\nfragment productsTable_LicenseProductFragment on Product {\n  id\n  title\n}\n\nfragment productsTable_ProductsConnectionFragment on ProductsConnection {\n  edges {\n    node {\n      ...productsTable_LicenseProductFragment\n      id\n    }\n  }\n}\n\nfragment selectProductField_QueryFragment on Query {\n  Admin {\n    Billing {\n      SearchProducts(first: 20, inKinds: [License]) {\n        pageInfo {\n          endCursor\n          hasPreviousPage\n          hasNextPage\n          startCursor\n        }\n        edges {\n          node {\n            id\n            __typename\n          }\n          cursor\n        }\n        ...productsTable_ProductsConnectionFragment\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6ddf011f872d4d6261422d7bfd6ec15d";

export default node;
