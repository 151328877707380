import { ButtonType, H2Span } from "@thekeytechnology/epic-ui";
import { useFormik } from "formik";
import { Carousel } from "primereact/carousel";
import { Divider } from "primereact/divider";
import { Toast } from "primereact/toast";
import React, { createRef, RefObject, useCallback, useRef, useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { Button, ButtonVariant } from "@components/button";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { ValidatedField } from "@components/ValidatedField";
import { Persolog_ClassType } from "@relay/persologClassForm_PersologClassFragment.graphql";
import { persologElementForm_EditPersologElementMutation } from "@relay/persologElementForm_EditPersologElementMutation.graphql";
import { persologElementForm_PersologElementFragment$key } from "@relay/persologElementForm_PersologElementFragment.graphql";
import { PersologClassForm } from "@screens/educational-offer/parts/persolog-class-form/persolog-class-form.component";
import {
	PersologClassFormRef,
	PersologClassFormState,
} from "@screens/educational-offer/parts/persolog-class-form/persolog-class-form.types";
import {
	NUMBER_OF_PERSOLOG_QUESTIONS_REQUIRED,
	persologClasses,
	PersologElementFormSchema,
} from "@screens/educational-offer/parts/persolog-element-form/persolog-element-form.consts";
import {
	EDIT_PERSOLOG_ELEMENT_MUTATION,
	PERSOLOG_ELEMENT_FRAGMENT,
} from "@screens/educational-offer/parts/persolog-element-form/persolog-element-form.graphql";
import {
	backButtonWrapper,
	carouselClass,
	carouselItemClass,
	questionLengthErrorClass,
} from "@screens/educational-offer/parts/persolog-element-form/persolog-element-form.styles";
import {
	PersologElementFormProps,
	PersologElementFormState,
} from "@screens/educational-offer/parts/persolog-element-form/persolog-element-form.types";
import { PersologElementQuestionsEditor } from "@screens/educational-offer/parts/persolog-element-questions-editor";
import { addEditedFormToEditedFormsArray, resetArrayOfEditedForms } from "@store/slices/CoreSlice";
import { useTypedDispatch } from "@store/store.redux";
import { P2Span } from "@themes/font-tags";

export const PersologElementForm = ({
	persologElementFragmentRef,
	onBack,
}: PersologElementFormProps) => {
	const toast = useRef<Toast>(null);

	const element = useFragment<persologElementForm_PersologElementFragment$key>(
		PERSOLOG_ELEMENT_FRAGMENT,
		persologElementFragmentRef,
	);
	const [amountOfQuestions, setAmountOfQuestions] = useState(element.questions.length ?? 0);

	const [editPersologElement, isEditingPersologElement] =
		useMutation<persologElementForm_EditPersologElementMutation>(
			EDIT_PERSOLOG_ELEMENT_MUTATION,
		);

	const dispatch = useTypedDispatch();
	const formId = "PersologElementForm";

	const initialValues = {
		title: element.title ?? "",
		classes: element.classes.map((persologClass) => ({
			title: persologClass.title,
			text: persologClass.text,
			classType: persologClass.classType,
			image: persologClass.image ?? undefined,
		})),
	};

	const formik = useFormik<PersologElementFormState>({
		initialValues,
		validationSchema: PersologElementFormSchema,
		onSubmit: (values) => {
			editPersologElement({
				variables: {
					input: {
						elementId: element.id,
						eLearningContentId: element.id,
						newTitle: values.title,
						newClasses: values.classes.map((persologClass) => ({
							title: persologClass.title,
							text: persologClass.text,
							classType: persologClass.classType,
							imageId: persologClass.image?.id,
						})),
					},
				},
				onCompleted: () => {
					dispatch(resetArrayOfEditedForms());
				},
			});
		},
	});
	const handleFormEdited = useCallback(() => {
		dispatch(addEditedFormToEditedFormsArray({ form: formId }));
	}, [dispatch]);

	const findExistingPersologClass = (classType: Persolog_ClassType) => {
		return element.classes.find((value) => value.classType === classType);
	};

	const persologClassFormRefs = useRef<Array<RefObject<PersologClassFormRef>>>([]);
	persologClassFormRefs.current = persologClasses.map(
		(_, i) => persologClassFormRefs.current[i] || createRef(),
	);

	const showClassFormError = (persologClass: string) => {
		toast.current?.show({
			severity: "error",
			life: 3000,
			summary: `Formular: ${persologClass}`,
			detail: `Einige Eingaben sind fehlerhaft`,
		});
	};
	const handleOnSave = async () => {
		const updatedClasses: PersologClassFormState[] = [];

		const classFormsValidationResults = await Promise.all(
			persologClassFormRefs.current.map(async (ref) => {
				const isValid = await ref.current?.validate();
				await ref.current?.submit();
				if (isValid) {
					ref.current?.values ? updatedClasses.push(ref.current?.values) : "";
				} else {
					showClassFormError(ref.current?.persologClass ?? "");
				}
				return isValid;
			}),
		);

		if (classFormsValidationResults.every((result) => result)) {
			await formik.setFieldValue("classes", updatedClasses);
			await formik.submitForm();
		}
	};

	const handleOnCreateQuestion = () => {
		setAmountOfQuestions((prevAmountOfQuestions) => prevAmountOfQuestions + 1);
	};

	const handleOnDeleteQuestion = () => {
		setAmountOfQuestions((prevAmountOfQuestions) => prevAmountOfQuestions - 1);
	};

	const persologClassesWithIndex = persologClasses.map((item, index) => ({
		...item,
		index,
	}));

	const hasEnoughQuestions = amountOfQuestions === NUMBER_OF_PERSOLOG_QUESTIONS_REQUIRED;

	return (
		<>
			<Toast ref={toast} />
			<ValidatedField<PersologElementFormState, string>
				name={"title"}
				label={"Titel"}
				component={DefaultTextFieldComponent}
				formikConfig={formik}
				onChange={handleFormEdited}
			/>
			<Divider />
			<H2Span>Klassen</H2Span>
			<Carousel
				className={carouselClass}
				value={persologClassesWithIndex}
				pt={{
					item: {
						className: carouselItemClass,
					},
				}}
				itemTemplate={(data) => {
					return (
						<PersologClassForm
							ref={persologClassFormRefs.current[data.index]}
							classType={data.classType}
							onChange={handleFormEdited}
							persologClassFragmentRef={findExistingPersologClass(data.classType)}
						/>
					);
				}}
			/>
			<Divider />
			<PersologElementQuestionsEditor
				persologElementFragmentRef={element}
				onCreateQuestion={handleOnCreateQuestion}
				onDeleteQuestion={handleOnDeleteQuestion}
			/>
			{!hasEnoughQuestions && (
				<P2Span className={questionLengthErrorClass}>
					Bitte erstelle genau 10 Fragen.
				</P2Span>
			)}
			<Divider />
			<Button
				disabled={isEditingPersologElement}
				type={ButtonType.Submit}
				label="Speichern"
				stretch
				onClick={handleOnSave}
			/>

			<div className={backButtonWrapper}>
				<Button
					disabled={!hasEnoughQuestions}
					variant={ButtonVariant.Strong}
					onClick={onBack}
					label="Zurück"
					stretch
				/>
			</div>
		</>
	);
};
